import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import 'date-fns';
import moment from 'moment';
import 'moment/locale/el';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import 'react-day-picker/lib/style.css';
import Draggable from 'react-draggable';
import useStylesChip from '../../src/theme/stylesChip';
import { ListboxComponent } from '../assets/Listbox';
import DatePickerWithClearIcon from '../dates/DatePickerWithClearIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    cursor: 'move',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 520,
  },
  formControlCodesLeft: {
    marginRight: theme.spacing(1),
    minWidth: 120,
    width: '50%',
  },
  formControlCodes: {
    minWidth: 120,
    width: '50%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  date: {
    display: 'flex',
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    padding: 0,
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginBottom: 0,
    alignItems: 'flex-end',
  },
  submitButton: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.primary.main,
    },
  },
  disabledButton: {
    backgroundColor: theme.palette.primary.main,
  },
  menuItemRoot: {
    '&$menuItemSelected, &$menuItemSelected:hover': {
      // backgroundColor: theme.palette.secondary.main,
      // color: theme.palette.primary.main,
      /**
       * TODO: may apply this minimal styling and remove
       * all colors from selected, hover
       */
      fontWeight: 430,
    },
    '&$menuItemSelected:hover': {
      // backgroundColor: theme.palette.secondary.main,
      // color: theme.palette.primary.main,
      fontWeight: 400,
    },
  },
  cancelButton: {
    color: theme.palette.secondary.light,
  },
  menuItemSelected: {},
  listbox: {
    padding: 0,
  },
  option: {
    '&[aria-selected="true"]': {
      // backgroundColor: theme.palette.secondary.main,
      // color: theme.palette.primary.main,
      fontWeight: 430,
    },
    '&[data-focus="true"][aria-selected="true"]:hover': {
      fontWeight: 400,
      // backgroundColor: theme.palette.secondary.light,
      // color: theme.palette.primary.main,
    },
  },
  radio: {
    margin: theme.spacing(1),
  },
  radioGroup: {
    display: 'inline',
  },
}));

function PaperComponent(props) {
  return (
    <Draggable
      handle='#draggable-modal-title'
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} id='dialog-paper' style={{ maxHeight: '100vh' }} />
    </Draggable>
  );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ModalDemography(props) {
  const router = useRouter();
  const classesChip = useStylesChip();

  const {
    submitFilters,
    handleCode,
    handleCodeObjectFrom,
    handleCodeObjectTo,
    handleCodeStartsWith,
    handleState,
    handleLegalForm,
    handleStartDateIncorporated,
    handleEndDateIncorporated,
    state,
    legalForm,
    states,
    legalForms,
    codeObject,
    codeObjectFrom,
    codeObjectTo,
    codeStartsWith,
    codes,
    selectedStartDateIncorporated,
    selectedEndDateIncorporated,
  } = props;
  const { open, handleClose } = props;
  const [errorOnDates, setErrorOnDates] = useState(false);
  const [errorOnStartDateIncorporated, setErrorOnStartDateIncorporated] =
    useState(false);
  const [errorOnEndDateIncorporated, setErrorOnEndDateIncorporated] =
    useState(false);
  const [errorOnDatesIncorporated, setErrorOnDatesIncorporated] =
    useState(false);
  const [errorOnDatesInterruption, setErrorOnDatesInterruption] =
    useState(false);

  const classes = useStyles();
  const [openCodes, setOpenCodes] = useState(false);
  const [openCodesFrom, setOpenCodesFrom] = useState(false);
  const [openCodesTo, setOpenCodesTo] = useState(false);

  moment.locale('el');

  const handleErrorOnStartDateIncorporated = (error) => {
    if (error.length == 0) {
      setErrorOnStartDateIncorporated(false);
    } else {
      setErrorOnStartDateIncorporated(true);
    }
    handleErrorOnDatesIncorporated();
  };

  const handleErrorOnEndDateIncorporated = (error) => {
    if (error.length == 0) {
      setErrorOnEndDateIncorporated(false);
    } else {
      setErrorOnEndDateIncorporated(true);
    }
    handleErrorOnDatesIncorporated();
  };

  const handleErrorOnDatesIncorporated = () => {
    if (errorOnEndDateIncorporated || errorOnStartDateIncorporated) {
      setErrorOnDatesIncorporated(true);
    } else {
      setErrorOnDatesIncorporated(false);
    }
  };

  const handleDelete = (index, id) => () => {
    let array;
    switch (index) {
      case 0:
        array = state.filter((option) => option !== id);
        handleState(array);
        break;
      case 1:
        array = legalForm.filter((option) => option !== id);
        handleLegalForm(array);
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        PaperComponent={PaperComponent}
        aria-labelledby='draggable-modal-title'
        style={{ cursor: 'move' }}
        className={classes.root}
        id='dialog'
      >
        <DialogTitle style={{ cursor: 'move' }} id='draggable-modal-title'>
          Φίλτρα
          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflowX: 'hidden',
          }}
          dividers
        >
          {router.pathname.includes('demography') ? (
            <>
              <Container id='date' className={classes.date}>
                <DatePickerWithClearIcon
                  handleStartDateChange={handleStartDateIncorporated}
                  handleEndDateChange={handleEndDateIncorporated}
                  selectedEndDate={selectedEndDateIncorporated}
                  selectedStartDate={selectedStartDateIncorporated}
                  handleErrorOnEndDate={handleErrorOnEndDateIncorporated}
                  handleErrorOnStartDate={handleErrorOnStartDateIncorporated}
                  labelFrom='Hμ/νία Σύστασης Από'
                  labelTo='Hμ/νία Σύστασης Έως'
                  fullWidth={true}
                />
              </Container>
            </>
          ) : (
            <></>
          )}
          <FormControl
            className={classes.formControl}
            style={{ marginTop: 0 }}
            color='secondary'
          >
            <InputLabel id='state-label' color='secondary'>
              Κατάσταση
            </InputLabel>
            <Select
              labelId='select-states'
              id='select-states'
              multiple
              value={state}
              onChange={(value) => handleState(value.target.value)}
              input={<Input id='select-state-chip' />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) =>
                    states
                      .filter((option) => option.id === value)
                      .map((final) => (
                        <Chip
                          variant='outlined'
                          key={final.id}
                          label={final.name}
                          className={classes.chip}
                          classes={{
                            label: classesChip.colorChip1,
                            root: classesChip.colorChipBorder1,
                            deleteIcon: classesChip.colorChip1,
                          }}
                          onMouseDown={(event) => {
                            event.stopPropagation();
                          }}
                          onDelete={handleDelete(0, final.id)}
                        />
                      ))
                  )}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {states.map((option) => (
                <MenuItem
                  classes={{
                    root: classes.menuItemRoot,
                    selected: classes.menuItemSelected,
                  }}
                  key={option.id}
                  value={option.id}
                >
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl} color='secondary'>
            <InputLabel id='legal-forms-label' color='secondary'>
              Νομικές Μορφές
            </InputLabel>
            <Select
              labelId='select-legal-forms'
              id='select-legal-forms'
              multiple
              value={legalForm}
              onChange={(value) => handleLegalForm(value.target.value)}
              input={<Input id='select-legal-forms-chip' />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value) =>
                    legalForms
                      .filter((option) => option.id === value)
                      .map((final) => (
                        <Chip
                          variant='outlined'
                          key={final.id}
                          label={final.name}
                          className={classes.chip}
                          classes={{
                            label: classesChip.colorChip2,
                            root: classesChip.colorChipBorder2,
                            deleteIcon: classesChip.colorChip2,
                          }}
                          onMouseDown={(event) => {
                            event.stopPropagation();
                          }}
                          onDelete={handleDelete(1, final.id)}
                        />
                      ))
                  )}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {legalForms.map((option) => (
                <MenuItem
                  classes={{
                    root: classes.menuItemRoot,
                    selected: classes.menuItemSelected,
                  }}
                  key={option.id}
                  value={option.id}
                >
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {(router.pathname.includes('demography') || router.pathname.includes('establishments') || router.pathname.includes('regdelgrouped') || router.pathname.includes('kadgrouped')) &&
            <Autocomplete
              classes={{
                option: classes.option,
                listbox: classes.listbox,
                tag: classesChip.colorChipAutocomplete4,
              }}
              ListboxComponent={ListboxComponent}
              disableCloseOnSelect
              disableListWrap
              multiple
              id='codes-autocomplete'
              className={classes.formControl}
              open={openCodes}
              value={codeObject}
              onOpen={() => {
                setOpenCodes(true);
              }}
              onClose={() => {
                setOpenCodes(false);
              }}
              getOptionSelected={(option, value) =>
                option.name === value.name
              }
              getOptionLabel={(option) => option.name}
              options={codes}
              noOptionsText='Κανένα αποτέλεσμα'
              onChange={(event, newValue) => {
                handleCode(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Επιλέξτε Δραστηριότητες'
                  color='secondary'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          }
          {router.pathname.includes('demography') || router.pathname.includes('kadgrouped') &&
            <>
              <TextField
                color='secondary'
                className={classes.formControl}
                id='codes-startsWith'
                label='Κωδικοί Δραστηριότητας που ξεκινούν με:'
                value={codeStartsWith}
                onChange={handleCodeStartsWith}
              />
              <div
                style={{ display: 'inline-flex' }}
                className={classes.formControl}
              >
                <Autocomplete
                  classes={{
                    option: classes.option,
                    listbox: classes.listbox,
                    tag: classesChip.colorChipAutocomplete4,
                  }}
                  id='codes-autocomplete'
                  className={classes.formControlCodesLeft}
                  ListboxComponent={ListboxComponent}
                  open={openCodesFrom}
                  value={codeObjectFrom}
                  onOpen={() => {
                    setOpenCodesFrom(true);
                  }}
                  onClose={() => {
                    setOpenCodesFrom(false);
                  }}
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  getOptionLabel={(option) => option.name}
                  options={codes}
                  noOptionsText='Κανένα αποτέλεσμα'
                  onChange={(event, newValue) => {
                    handleCodeObjectFrom(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Κωδικός Δραστηριότητας από:'
                      color='secondary'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                <Autocomplete
                  classes={{
                    option: classes.option,
                    listbox: classes.listbox,
                    tag: classesChip.colorChipAutocomplete4,
                  }}
                  id='codes-autocomplete'
                  className={classes.formControlCodes}
                  ListboxComponent={ListboxComponent}
                  open={openCodesTo}
                  value={codeObjectTo}
                  onOpen={() => {
                    setOpenCodesTo(true);
                  }}
                  onClose={() => {
                    setOpenCodesTo(false);
                  }}
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  getOptionLabel={(option) => option.name}
                  options={codes}
                  noOptionsText='Κανένα αποτέλεσμα'
                  onChange={(event, newValue) => {
                    handleCodeObjectTo(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='έως'
                      color='secondary'
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
            </>
          }
          {/* TODO: conditional rendering for legalforms and departments */}
          {/* {type === types[0].id && router.pathname.includes("demography") ? (
            <></>
          ) : ( */}

          {/* )} */}
          {/* {type === types[1].id && router.pathname.includes("demography") ? (
            <></>
          ) : ( */}

          {/* )} */}
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={handleClose}
            variant='outlined'
            className={classes.cancelButton}
          >
            ΑΚΥΡΩΣΗ
          </Button>
          <Button
            onClick={submitFilters}
            variant='outlined'
            classes={{
              disabled: classes.disabledButton,
            }}
            className={classes.submitButton}
            disabled={
              errorOnDates ||
              errorOnDatesIncorporated ||
              errorOnDatesInterruption
            }
          >
            ΕΦΑΡΜΟΓΗ
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
