import { Box, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import clsx from 'clsx';
import { format } from 'd3-format';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { isTablet } from 'react-device-detect';
import Jump from 'react-reveal/Jump';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Hint, RadialChart } from 'react-vis';
import useSWR from 'swr';
import { BodyCompareHandling } from '../../../src/context/BodyCompareContext';
import { BodyHandling } from '../../../src/context/BodyContext';
import { CompareHandling } from '../../../src/context/CompareContext';
import { TypeHandling } from '../../../src/context/TypeContext';
import getIncomeByType from '../../../src/requests/client/getIncomeByType';
import DotLoader from '../../assets/DotLoader';
import ErrorOnRequest from '../../assets/ErrorOnRequest';
import f from '../../../src/format/format';

const useStyles = makeStyles((theme) => ({
  paperLoader: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '85%',
    maxHeight: 450,
  },
  typographyTitle: {
    display: 'flex',
    flex: 1,
  },
  group: {
    justifyContent: 'flex-start',
    display: 'flex',
    verticalAlign: 'top',
    width: '100%',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    alignContent: 'stretch',
  },
  descriptionTitle: {
    marginLeft: theme.spacing(1),
    fontWeight: 450,
    fontSize: '0.9rem',
  },
  detailsDescription: {
    marginLeft: theme.spacing(1),
  },
  rise: {
    color: theme.palette.success.main,
  },
  decrease: {
    color: theme.palette.error.main,
  },
  boxDescription: {
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  description: {
    width: '100%',
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    alignContent: 'center',
    alignSelf: 'flex-end',
    flexGrow: 1,
    [theme.breakpoints.down('lg')]: {
      alignSelf: 'baseline',
    },
  },
  noResultButton: {
    borderRadius: 0,
    cursor: 'auto',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    height: '70%',
  },
  hint: {
    border: '1px solid',
    borderRadius: 5,
    borderColor: theme.palette.secondary.main,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.secondary.main,
  },
}));

const PieInsights = (props) => {
  const classes = useStyles();
  const [finalData, setFinalData] = useState(undefined);
  const [finalDataCompare, setFinalDataCompare] = useState(undefined);
  const [descriptionObjectForComparison, setDescriptionObjectForComparison] =
    useState();
  const [errorOnRequest, setErrorOnRequest] = useState(false);
  const [colorsIndex, setColorsIndex] = useState([]);
  const [value, setValue] = useState(false);
  const [valueCompare, setValueCompare] = useState(false);
  const [sum, setSum] = useState();
  const [sumCompare, setSumCompare] = useState();
  const theme = useTheme();
  const { bodyInsight } = useContext(BodyHandling);
  const { compareBodyInsight } = useContext(BodyCompareHandling);
  const { regDelCompareType } = useContext(TypeHandling);
  const { compare } = useContext(CompareHandling);

  const method = props.method;
  const url = props.url;
  let body = bodyInsight;
  let bodyCompare = compareBodyInsight;

  const { data: data } = useSWR(['/byType', bodyInsight], () =>
    getIncomeByType(method, url, null, body)
  );

  const { data: dataCompare } = useSWR(
    ['/byTypeCompare', compareBodyInsight],
    () => getIncomeByType(method, url, null, bodyCompare)
  );

  const MouseOver = (v, width) => {
    v.proportion = ((v.yHint / sum) * 100).toFixed(2).concat('%');
    if (width < 500 && compare) {
      v.x = 0.9;
      v.y = 0.4;
    }
    setValue(v);
  };

  const MouseOverCompare = (v, width) => {
    v.proportion = ((v.yHint / sumCompare) * 100).toFixed(2).concat('%');
    if (width < 500 && compare) {
      v.x = 0.9;
      v.y = 0.4;
    }
    setValueCompare(v);
  };

  const myPalette = [
    `${theme.palette.reg.main}`, `${theme.palette.del.main}`
  ];

  const myPaletteRegDel = [
    `${theme.palette.reg.main}`, `${theme.palette.del.main}`
  ];

  const caseTabletCompare = (height) => {
    return (1 * height) / 3;
  };

  const caseTablet = (height) => {
    return (1 * height) / 2;
  };

  const caseWeb = (height) => {
    return (2 * height) / 3;
  };

  const editData = (array) => {
    let regObj = {};
    let delObj = {};
    let finalData = [];
    let sum;

    regObj.x = 'Εγγραφές';
    delObj.x = 'Διαγραφές';
    regObj.title = 'Εγγραφές';
    delObj.title = 'Διαγραφές';

    regObj.color = '0';
    delObj.color = '1';

    regObj.y = array.countRegistrations;
    regObj.yHint = array.countRegistrations;
    delObj.y = array.countDeletions;
    delObj.yHint = array.countDeletions;
    finalData.push(regObj, delObj);
    sum = finalData[0].y + finalData[1].y;
    if (finalData) {
      if (
        (finalData[0].y === 0 && finalData[1].y === 0) ||
        (!finalData[0].y && !finalData[1].y)
      ) {
        finalData = [];
      }
    }
    return { finalData, sum };
  };

  const addDetailsForComparison = (
    descriptionArray,
    index,
    description,
    firstArray,
    secondArray
  ) => {
    if (secondArray.length > 0) {
      if (secondArray[index].y !== 0) {
        descriptionArray.push({
          x: description,
          difPercentage:
            firstArray[index].y - secondArray[index].y > 0
              ? (
                  ((firstArray[index].y - secondArray[index].y) /
                    secondArray[index].y) *
                  100
                ).toFixed(2)
              : (
                  ((secondArray[index].y - firstArray[index].y) /
                    secondArray[index].y) *
                  100
                ).toFixed(2),
          rise: secondArray[index].y === 0 ? null : firstArray[index].y - secondArray[index].y > 0 ? true : false,
        });
      }
    } else if (firstArray.length > 0) {
      if (firstArray[index].y !== 0) {
        descriptionArray.push({
          x: description,
          difPercentage: (100).toFixed(2),
          rise: false,
        });
      }
    }
    return { descriptionArray };
  };

  useEffect(() => {
    if (data && typeof data !== 'number') {
      const dataArray = JSON.parse(JSON.stringify(data));
      const finalDataArray = editData(dataArray).finalData;
      setFinalData(editData(dataArray).finalData);
      setSum(editData(dataArray).sum);
      setErrorOnRequest(false);
      setColorsIndex(['0', '1']);

      if (compare) {
        if (dataCompare && typeof dataCompare !== 'number') {
          const dataArrayCompare = JSON.parse(JSON.stringify(dataCompare));
          const finalDataArrayCompare = editData(dataArrayCompare).finalData;
          setFinalDataCompare(editData(dataArrayCompare).finalData);
          setSumCompare(editData(dataArrayCompare).sum);
          setErrorOnRequest(false);
          setColorsIndex(['0', '1']);
          let descriptionArray = [];

          descriptionArray = addDetailsForComparison(
            descriptionArray,
            0,
            'Εγγραφές',
            finalDataArray,
            finalDataArrayCompare
          ).descriptionArray;

          descriptionArray = addDetailsForComparison(
            descriptionArray,
            1,
            'Διαγραφές',
            finalDataArray,
            finalDataArrayCompare
          ).descriptionArray;

          setDescriptionObjectForComparison(descriptionArray);
        }
      }
    }
    if (typeof data === 'number' || typeof dataCompare === 'number') {
      setErrorOnRequest(true);
    }
  }, [data, dataCompare]);

  if (errorOnRequest) {
    return (
      <ErrorOnRequest
        title={props.title
          .concat(' ')

          .concat(
            compare
              ? ''
              : `(${moment(bodyInsight.startDate).format(
                  'DD/MM/YYYY'
                )} - ${moment(bodyInsight.endDate).format('DD/MM/YYYY')})`
          )}
      />
    );
  }

  if (!data)
    return (
      <DotLoader
        title={
          url.includes('/gemiIncome/byType')
            ? `${
                bodyInsight.type === 1 ? 'Ποσό Επιμελητηρίου' : 'Συνολικό Ποσό'
              }`
            : `${props.title}`
        }
      />
    );

  if (finalData && data && dataCompare) {
    return (
      <>
        <Grid item xs={12} className={classes.group}>
          <div style={{ display: 'contents' }}>
            <Typography
              component="h6"
              variant="h6"
              colSpan={6}
              className={classes.typographyTitle}
            >
              {props.title}
            </Typography>
          </div>
        </Grid>
        <Paper className={classes.paperLoader}>
          <>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                position: 'relative',
              }}
            >
              <AutoSizer id="autosizer" style={{ height: 'inherit' }}>
                {({ height, width }) => (
                  <>
                    <div
                      id="dates-for-compare"
                      style={{
                        width: width,
                        display: 'inline-flex',
                        justifyContent: compare ? 'space-around' : 'center',
                        marginBottom: theme.spacing(1),
                      }}
                    >
                      <div
                        style={{
                          fontSize:
                            height < 350 && width < 500 ? '0.8rem' : '0.9rem',
                        }}
                      >
                        {moment(bodyInsight.startDate)
                          .format('DD/MM/YYYY')
                          .concat('-')
                          .concat(
                            moment(bodyInsight.endDate).format('DD/MM/YYYY')
                          )}
                      </div>
                      <div
                        style={{
                          fontSize:
                            height < 350 && width < 500 ? '0.8rem' : '0.9rem',
                        }}
                      >
                        {compare ? (
                          moment(compareBodyInsight.startDate)
                            .format('DD/MM/YYYY')
                            .concat('-')
                            .concat(
                              moment(compareBodyInsight.endDate).format(
                                'DD/MM/YYYY'
                              )
                            )
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div
                      id="ref"
                      style={{ display: 'inline-flex', width: width }}
                    >
                      <Jump>
                        {finalData.length > 0 ? (
                          <RadialChart
                            onValueMouseOver={(v) => MouseOver(v, width)}
                            onSeriesMouseOut={() => setValue(false)}
                            colorType="category"
                            colorDomain={colorsIndex}
                            colorRange={
                              regDelCompareType ? myPaletteRegDel : myPalette
                            }
                            getAngle={(d) => d.y}
                            data={finalData}
                            radius={
                              (compare && width < 550) ||
                              (height < 350 && width < 500) ||
                              width < 550 ||
                              isTablet
                                ? isTablet && compare
                                  ? (2 * height) / 16
                                  : (2 * height) / 8
                                : (2 * height) / 6
                            }
                            width={compare ? (1 * width) / 2 : width}
                            height={
                              (compare && width < 550) ||
                              (height < 350 && width < 500) ||
                              width < 550 ||
                              isTablet
                                ? isTablet && compare
                                  ? caseTabletCompare(height)
                                  : caseTablet(height)
                                : caseWeb(height)
                            }
                            showLabels={true}
                            getLabel={(d) =>
                              ((d.y / sum) * 100).toFixed(2).concat('%')
                            }
                            labelsRadiusMultiplier={0.8}
                            style={{ stroke: '#fff', strokeWidth: '2' }}
                            labelsStyle={{
                              fontSize:
                                (compare && width < 550) ||
                                (height < 350 && width < 500)
                                  ? 12
                                  : 14,
                              fill: `${theme.palette.primary.main}`,
                            }}
                          >
                            {value !== false && (
                              <Hint value={value} id="hint">
                                <div className={classes.hint}>
                                  <div>{value.title}</div>
                                  {compare ? (
                                    <div
                                      style={{
                                        display: 'flex',
                                        width: 'max-content',
                                      }}
                                    >
                                      Πλήθος: &nbsp;{' '}
                                      <div
                                        style={{
                                          color: myPalette[value.color],
                                        }}
                                      >
                                        {value.yHint}
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  <div
                                    style={{
                                      display: 'inline-flex',
                                      width: 'max-content',
                                    }}
                                  >
                                    Ποσοστό: &nbsp;{' '}
                                    <div
                                      style={{
                                        color: regDelCompareType
                                          ? myPaletteRegDel[value.color]
                                          : myPalette[value.color],
                                      }}
                                    >
                                      {value.proportion}
                                    </div>
                                  </div>
                                </div>
                              </Hint>
                            )}
                          </RadialChart>
                        ) : (
                          <ContentLoader
                            id="loader-no-results"
                            title="Δε βρέθηκαν αποτελέσματα"
                            animate={false}
                            height={
                              (compare && width < 550) ||
                              (height < 350 && width < 500) ||
                              isTablet
                                ? isTablet && compare
                                  ? caseTabletCompare(height)
                                  : caseTablet(height)
                                : caseWeb(height)
                            }
                            width={compare ? width / 2 : width}
                            backgroundColor="transparent"
                            gradientRatio={0}
                          >
                            <circle
                              cx={compare ? width / 4 : width / 2}
                              cy={
                                (compare && width < 550) ||
                                (height < 350 && width < 500) ||
                                isTablet
                                  ? isTablet && compare
                                    ? (2 * height) / 12
                                    : (2 * height) / 8
                                  : (2 * height) / 6
                              }
                              r={
                                (compare && width < 550) ||
                                (height < 350 && width < 500) ||
                                isTablet
                                  ? isTablet && compare
                                    ? (2 * height) / 12
                                    : (2 * height) / 8
                                  : (2 * height) / 6
                              }
                            />
                            <rect
                              x={compare ? (1 * width) / 8 : (1 * width) / 4}
                              y={(3 * height) / 4}
                              rx="0"
                              ry="0"
                              width={(2 * width) / 4}
                              height="10"
                            />
                          </ContentLoader>
                        )}
                        {compare ? (
                          finalDataCompare && finalDataCompare.length > 0 ? (
                            <RadialChart
                              onValueMouseOver={(v) =>
                                MouseOverCompare(v, width)
                              }
                              onSeriesMouseOut={() => setValueCompare(false)}
                              colorType="category"
                              colorDomain={colorsIndex}
                              colorRange={
                                regDelCompareType ? myPaletteRegDel : myPalette
                              }
                              getAngle={(d) => d.y}
                              data={finalDataCompare}
                              radius={
                                (compare && width < 550) ||
                                (height < 350 && width < 500) ||
                                width < 550 ||
                                isTablet
                                  ? isTablet && compare
                                    ? (2 * height) / 16
                                    : (2 * height) / 8
                                  : (2 * height) / 6
                              }
                              width={compare ? (1 * width) / 2 : width}
                              height={
                                (compare && width < 550) ||
                                (height < 350 && width < 500) ||
                                width < 550 ||
                                isTablet
                                  ? isTablet && compare
                                    ? caseTabletCompare(height)
                                    : caseTablet(height)
                                  : caseWeb(height)
                              }
                              showLabels={true}
                              getLabel={(d) =>
                                ((d.y / sumCompare) * 100)
                                  .toFixed(2)
                                  .concat('%')
                              }
                              labelsRadiusMultiplier={0.8}
                              style={{ stroke: '#fff', strokeWidth: '2' }}
                              labelsStyle={{
                                fontSize:
                                  (compare && width < 550) ||
                                  (height < 350 && width < 500)
                                    ? 12
                                    : 14,
                                fill: `${theme.palette.primary.main}`,
                              }}
                            >
                              {valueCompare !== false && (
                                <Hint value={valueCompare} id="hint">
                                  <div className={classes.hint}>
                                    <div>{valueCompare.title}</div>
                                    {compare ? (
                                      <div
                                        style={{
                                          display: 'flex',
                                          width: 'max-content',
                                        }}
                                      >
                                        Πλήθος: &nbsp;{' '}
                                        <div
                                          style={{
                                            color:
                                              myPalette[valueCompare.color],
                                          }}
                                        >
                                          {valueCompare.yHint}
                                        </div>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                    <div
                                      style={{
                                        display: 'inline-flex',
                                        width: 'max-content',
                                      }}
                                    >
                                      Ποσοστό: &nbsp;{' '}
                                      <div
                                        style={{
                                          color: regDelCompareType
                                            ? myPaletteRegDel[
                                                valueCompare.color
                                              ]
                                            : myPalette[valueCompare.color],
                                        }}
                                      >
                                        {valueCompare.proportion}
                                      </div>
                                    </div>
                                  </div>
                                </Hint>
                              )}
                            </RadialChart>
                          ) : (
                            <ContentLoader
                              id="loader-no-results"
                              title="Δε βρέθηκαν αποτελέσματα"
                              animate={false}
                              height={
                                (compare && width < 550) ||
                                (height < 350 && width < 500) ||
                                isTablet
                                  ? isTablet && compare
                                    ? caseTabletCompare(height)
                                    : caseTablet(height)
                                  : caseWeb(height)
                              }
                              width={compare ? width / 2 : width}
                              backgroundColor="transparent"
                              gradientRatio={0}
                            >
                              <circle
                                cx={compare ? width / 4 : width / 2}
                                cy={
                                  (compare && width < 550) ||
                                  (height < 350 && width < 500) ||
                                  isTablet
                                    ? isTablet && compare
                                      ? (2 * height) / 12
                                      : (2 * height) / 8
                                    : (2 * height) / 6
                                }
                                r={
                                  (compare && width < 550) ||
                                  (height < 350 && width < 500) ||
                                  isTablet
                                    ? isTablet && compare
                                      ? (2 * height) / 12
                                      : (2 * height) / 8
                                    : (2 * height) / 6
                                }
                              />
                              <rect
                                x={compare ? (1 * width) / 8 : (1 * width) / 4}
                                y={(3 * height) / 4}
                                rx="0"
                                ry="0"
                                width={(2 * width) / 4}
                                height="10"
                              />
                            </ContentLoader>
                          )
                        ) : (
                          <></>
                        )}
                      </Jump>
                    </div>
                  </>
                )}
              </AutoSizer>
              <div className={classes.description}>
                {compare &&
                descriptionObjectForComparison &&
                descriptionObjectForComparison.length > 0 ? (
                  descriptionObjectForComparison.map(function (option, i) {
                    return (
                      <Box
                        key={option.x}
                        display="flex"
                        flexWrap="nowrap"
                        bgcolor="background.paper"
                        className={classes.boxDescription}
                      >
                        <Box
                          p={1}
                          bgcolor={myPalette[i]}
                          style={{ borderRadius: '50%' }}
                        ></Box>
                        <div className={classes.detailsDescription}>
                          <Typography
                            className={classes.descriptionTitle}
                            style={{
                              fontWeight: 450,
                              fontSize: '0.9rem',
                            }}
                          >
                            {option.x}
                          </Typography>
                          {option.rise ? (
                            <div style={{ display: 'inline-flex' }}>
                              <ArrowUpwardIcon
                                fontSize="small"
                                className={classes.rise}
                              />
                              <Typography
                                color="textSecondary"
                                className={clsx(
                                  classes.descriptionTitle,
                                  classes.rise
                                )}
                              >
                                {f(option.difPercentage).concat('%')}
                              </Typography>
                            </div>
                          ) : option.rise === false ? (
                            <div style={{ display: 'inline-flex' }}>
                              <ArrowDownwardIcon
                                fontSize="small"
                                className={classes.decrease}
                              />
                              <Typography
                                color="textSecondary"
                                className={clsx(
                                  classes.descriptionTitle,
                                  classes.decrease
                                )}
                              >
                                {f(option.difPercentage).concat('%')}
                              </Typography>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </Box>
                    );
                  })
                ) : finalData.length > 0 ? (
                  finalData.map(function (option, i) {
                    return (
                      <Box
                        key={option.x}
                        display="flex"
                        flexWrap="nowrap"
                        bgcolor="background.paper"
                        className={classes.boxDescription}
                      >
                        <Box
                          p={1}
                          bgcolor={
                            regDelCompareType
                              ? myPaletteRegDel[i]
                              : myPalette[i]
                          }
                          style={{ borderRadius: '50%' }}
                        ></Box>
                        <div className={classes.detailsDescription}>
                          <Typography
                            style={{
                              fontWeight: 450,
                              fontSize: '0.9rem',
                            }}
                          >
                            {option.x}
                          </Typography>
                          <div style={{ display: 'inline-flex' }}>
                            <Typography
                              color="textSecondary"
                              style={{
                                fontWeight: 450,
                                fontSize: '0.9rem',
                              }}
                            >
                              {format(',d')(option.y)}
                            </Typography>
                          </div>
                        </div>
                      </Box>
                    );
                  })
                ) : (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      id="filter-button"
                      type="submit"
                      variant="outlined"
                      className={classes.noResultButton}
                    >
                      Δε βρεθηκαν αποτελεσματα
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </>
        </Paper>
      </>
    );
  }

  return (
    <DotLoader
      title={
        url.includes('/gemiIncome/byType')
          ? `${bodyInsight.type === 1 ? 'Ποσό Επιμελητηρίου' : 'Συνολικό Ποσό'}`
          : `${props.title}`
      }
    />
  );
};

export default PieInsights;
