import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { format } from 'd3-format';
import moment from 'moment';
import 'moment/locale/el';
import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { DataHandling } from '../../src/context/DataContext';
import { DateIndexHandling } from '../../src/context/DateIndexContext';
import { FreeRangeCompareHandling } from '../../src/context/FreeRangeCompareContext';
import { TypeHandling } from '../../src/context/TypeContext';

const useStyles = makeStyles((theme) => ({
  paperForPrint: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: theme.spacing(1),
  },
  background: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
  },
}));

const EstablishmentsTableForPrint = () => {
  const classes = useStyles();
  const { data } = useContext(DataHandling);
  const { currentDateIndex } = useContext(DateIndexHandling);
  const [dataTable, setDataTable] = useState([]);
  const { freeRangeCompare } = useContext(FreeRangeCompareHandling);
  const { regDelCompareType } = useContext(TypeHandling);
  const [compare, setCompare] = useState();
  const componentRef = useRef();

  useEffect(() => {
    if (data && data !== undefined) {
      getData();
    }
    return () => {
      setDataTable([]);
    };
  }, [data, currentDateIndex]);

  const getData = () => {
    try {
      let array;
      if (!freeRangeCompare) {
        if (data.length > 1) {
          setCompare(true);
          array = JSON.parse(JSON.stringify(data));
          if (array[0].length !== array[1].length) {
            if (array[1].length > array[0].length) {
              let diff = array[1].length - array[0].length;
              let diffVar;
              for (diffVar = 0; diffVar < diff; diffVar++) {
                array[1].pop();
              }
            }
            if (array[0].length > array[1].length) {
              let diff = array[0].length - array[1].length;
              let diffVar;
              for (diffVar = 0; diffVar < diff; diffVar++) {
                array[0].pop();
              }
            }
          }
          for (const i in array) {
            if (i == 0) {
              for (const j in array[i]) {
                array[i][j].change = array[0][j].y - array[1][j].y;
              }
            }
          }
          let tableArray = [];
          for (const i in array) {
            for (const j in array[i]) {
              tableArray[j] = [array[0][j], array[1][j]];
            }
          }
          setDataTable(tableArray);
        } else {
          setCompare(false);
          setDataTable(data);
        }
      } else {
        if (data.length > 1) {
          array = JSON.parse(JSON.stringify(data));
          setCompare(true);
          let table = [array[0]];
          array[1].map((entry) => table[0].push(entry));
          setDataTable(table);
        } else {
          setCompare(false);
          setDataTable(data);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const dateComparisonLabel = () => {
    switch (currentDateIndex) {
      case 0:
        return 'Ημερομηνία Σύγκρισης';
      case 1:
        return 'Εβδομάδα Σύγκρισης (που ξεκινάει από)';
      case 2:
        return 'Μήνας Σύγκρισης';
      case 3:
        return 'Έτος Σύγκρισης';
    }
  };

  const dateLabel = () => {
    switch (currentDateIndex) {
      case 0:
        return 'Ημερομηνία';
      case 1:
        return 'Εβδομάδα (που ξεκινάει από)';
      case 2:
        return 'Μήνας';
      case 3:
        return 'Έτος';
    }
  };

  const dateFormat = (value) => {
    switch (currentDateIndex) {
      case 0:
        return moment(value).format('DD/MM/YYYY');
      case 1:
        return moment(value).format('DD/MM/YYYY');
      case 2:
        return moment(value).format('MM/YYYY');
      case 3:
        return moment(value).format('YYYY');
      case 4:
        return moment(value).format('DD/MM/YYYY');
      case 5:
        return moment(value).format('DD/MM/YYYY');
    }
  };

  return (
    <TableContainer ref={componentRef} className={classes.paperForPrint} tabIndex={0}>
      <Table stickyHeader aria-label='sticky table'>
        <TableHead id='header'>
          <TableRow>
            <TableCell
              id='body'
              align='left'
              m={1}
              className={classes.background}
            >
              {dateLabel()}
            </TableCell>
            {regDelCompareType ? null : (
              <TableCell
                id='slug'
                align='right'
                m={1}
                className={classes.background}
              >
                Πλήθος
              </TableCell>
            )}
            {!freeRangeCompare && compare ? (
              regDelCompareType ? (
                <>
                  <TableCell
                    id='slug'
                    align='right'
                    m={1}
                    className={classes.background}
                  >
                    Πλήθος Εγγραφών
                  </TableCell>
                  <TableCell
                    id='slug'
                    align='right'
                    m={1}
                    className={classes.background}
                  >
                    Πλήθος Διαγραφών
                  </TableCell>
                  <TableCell
                    id='slug'
                    align='right'
                    m={1}
                    className={classes.background}
                  >
                    Μεταβολή (Εγγραφές-Διαγραφές)
                  </TableCell>
                </>
              ) : (
                <>
                  <TableCell
                    id='slug'
                    align='right'
                    m={1}
                    className={classes.background}
                  >
                    {dateComparisonLabel()}
                  </TableCell>
                  <TableCell
                    id='slug'
                    align='right'
                    m={1}
                    className={classes.background}
                  >
                    Πλήθος Σύγκρισης
                  </TableCell>
                  <TableCell
                    id='slug'
                    align='right'
                    m={1}
                    className={classes.background}
                  >
                    Μεταβολή
                  </TableCell>
                </>
              )
            ) : null}
          </TableRow>
        </TableHead>
        <TableBody id='table-body'>
          {dataTable.length > 0 ? (
            !freeRangeCompare && compare ? (
              dataTable.map(function (data) {
                return (
                  <Fragment key={data[0].id}>
                    <TableRow key={data[0].id} id={data[0].id}>
                      <TableCell>{dateFormat(data[0].x)}</TableCell>
                      <TableCell align='right'>
                        {format(',d')(data[0].y)}
                      </TableCell>
                      {regDelCompareType ? null : (
                        <TableCell align='right'>
                          {dateFormat(data[1].x)}
                        </TableCell>
                      )}
                      <TableCell align='right'>
                        {format(',d')(data[1].y)}
                      </TableCell>
                      <TableCell align='right'>
                        {format(',d')(data[0].change)}
                      </TableCell>
                    </TableRow>
                  </Fragment>
                );
              })
            ) : (
              dataTable.map(function (option) {
                return option.map(function (data) {
                  return (
                    <Fragment key={data.id}>
                      <TableRow key={data.id} id={data.id}>
                        <TableCell>{dateFormat(data.x)}</TableCell>
                        <TableCell align='right'>
                          {format(',d')(data.y)}
                        </TableCell>
                      </TableRow>
                    </Fragment>
                  );
                });
              })
            )
          ) : (
            <></>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EstablishmentsTableForPrint;
