import { Typography } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Chip from '@material-ui/core/Chip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Select from '@material-ui/core/Select';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import AddCircleSharpIcon from '@material-ui/icons/AddCircleSharp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { isTablet } from 'react-device-detect';
import { BodyCompareHandling } from '../../src/context/BodyCompareContext';
import { BodyHandling } from '../../src/context/BodyContext';
import { CompareHandling } from '../../src/context/CompareContext';
import { DataHandling } from '../../src/context/DataContext';
import { DateIndexHandling } from '../../src/context/DateIndexContext';
import { DateYearsHandling } from '../../src/context/DateYearsContext';
import { ErrorHandling } from '../../src/context/ErrorContext';
import { FreeRangeCompareHandling } from '../../src/context/FreeRangeCompareContext';
import { LoadingHandling } from '../../src/context/LoadingContext';
import { TypeHandling } from '../../src/context/TypeContext';
import dataTest from '../../src/dataTest/dataTest.json';
import { getEstablishments } from '../../src/requests/client/getEstablishments';
import useStylesChip from '../../src/theme/stylesChip';
import DateRangePicker from '../dates/DateRangePicker';
import DateRangePickerCompare from '../dates/DateRangePickerCompare';
import Modal from './Modal';
import DateYearPicker from '../dates/DateYearPicker';
import ModalDemography from './ModalDemography';

const options = [
  'Χωρίς σύγκριση',
  'Προηγούμενο διάστημα',
  'Προηγούμενο έτος',
  'Ελεύθερο διάστημα',
];

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '25ch',
    },
  },
  paper: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  paperYears: {
    display: 'inline',
    alignItems: 'baseline',
    boxShadow: 'none',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  typographyTitle: {
    display: 'flex',
    flex: 1,
  },
  typographyDates: {
    display: 'flex',
    flex: 1,
    margin: theme.spacing(2),
  },
  titleButton: {
    justifyContent: 'flex-start',
    display: 'flex',
    verticalAlign: 'top',
    width: '100%',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    alignContent: 'stretch',
  },
  selectedDatesYear: {
    paddinTop: 0,
    paddingBottom: 0,
    color: theme.palette.disabled,
    background: theme.palette.primary.main,
    '&.Mui-selected': {
      color: theme.palette.secondary.main,
      background: theme.palette.primary.main,
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: theme.palette.secondary.main,
    },
  },
  dateYearComparison: {
    display: 'flex',
    alignItems: 'baseline',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },
  submitGrid: {
    display: 'inline-flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-end',
  },
  searchButton: {
    margin: theme.spacing(2),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    borderRadius: 0,
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.primary.main,
    },
  },
  filters: {
    display: 'inline-flex',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'contents',
    },
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 170,
    maxWidth: 400,
  },
  dates: {
    margin: theme.spacing(2),
  },
  checkboxCompare: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(4),
    display: 'grid',
    width: 100,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  checkbox: {
    justifySelf: 'center',
    padding: '0',
  },
  heading: {
    textAlign: 'center',
    marginBottom: theme.spacing(1),
  },
  yearsWrapper: {
    margin: theme.spacing(2),
    display: 'flex',
    flex: '1',
  },
  accordion: {
    boxShadow: 'none',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: '5px',
    borderColor: fade(theme.palette.secondary.main, 0.15),
  },
  accordionTitle: {
    maxHeight: 60,
    paddingLeft: 0,
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  filterChip: {
    width: '100%',
    margin: theme.spacing(2),
  },
  chip: {
    margin: theme.spacing(0.5),
    [theme.breakpoints.between('sm', 'md')]: {
      maxWidth: '235px',
    },
  },
}));

const StyledBadge = withStyles(() => ({
  badge: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: fade('#000000', 0.5),
    zIndex: 0,
  },
}))(Badge);

const FiltersEstablishments = (props) => {
  const classes = useStyles();
  const classsesChip = useStylesChip();
  const { title, types, states, legalForms, codes } = props;

  const [type, setType] = useState(types[0].id);

  const [requiredYearsFlag, setRequiredYearsFlag] = useState(false);
  const [requiredDatesFlag, setRequiredDatesFlag] = useState(false);

  const [startDate, setStartDate] = useState(
    moment().utcOffset(0, true).startOf('month').toDate()
  );
  const [endDate, setEndDate] = useState(moment().utcOffset(0, true).toDate());

  const [startYear, handleStartYear] = useState(null);
  const [endYear, handleEndYear] = useState(null);

  const [compareStartDate, setCompareStartDate] = useState(undefined);
  const [compareEndDate, setCompareEndDate] = useState(undefined);

  const [checked, setChecked] = useState(false);
  const [state, setState] = useState([]);
  const [legalForm, setLegalForm] = useState([]);

  const [finalStatesArray, setFinalStatesArray] = useState([]);
  const [finalLegalFormsArray, setFinalLegalFormsArray] = useState([]);

  const [disabledDates, setDisabledDates] = useState(false);
  const [selectDatesYearsIndex, setSelectDatesYearsIndex] = useState(0);
  const [errorOnStartYear, setErrorOnStartYear] = useState(false);
  const [errorOnEndYear, setErrorOnEndYear] = useState(false);
  const [errorOnYears, setErrorOnYears] = useState(false);
  const [code, setCode] = useState([]);
  const [codeObject, setCodeObject] = useState([]);
  const [finalCode, setFinalCode] = useState([]);
  const [finalCodesArray, setFinalCodesArray] = useState([]);

  const { setDateYearsSelected } = useContext(DateYearsHandling);
  const { setOpenModal } = useContext(ErrorHandling);
  const { currentDateIndex, setCurrentDateIndex } =
    useContext(DateIndexHandling);
  const [open, setOpen] = useState(false);
  const { setFreeRangeCompare } = useContext(FreeRangeCompareHandling);
  const { setCompare } = useContext(CompareHandling);

  const { setBodyInsight } = useContext(BodyHandling);
  const { setCompareBodyInsight } = useContext(BodyCompareHandling);

  const { setRegDelCompareType } = useContext(TypeHandling);
  const { setLoadingState } = useContext(LoadingHandling);

  const { setData } = useContext(DataHandling);

  const [expanded, setExpanded] = useState('');

  const [body, setBody] = useState({
    type: type,
    startDate: startDate ? moment(startDate).utcOffset(0, true).format('YYYY-MM-DD') : '',
    endDate: endDate ? moment(endDate).utcOffset(0, true).format('YYYY-MM-DD') : '',
    compareStartDate: compareStartDate
      ? moment(compareStartDate).utcOffset(0, true).format('YYYY-MM-DD')
      : '',
    compareEndDate: compareEndDate
      ? moment(compareEndDate).utcOffset(0, true).format('YYYY-MM-DD')
      : '',
    fromYear: startYear ? moment(startYear).utcOffset(0, true).format('YYYY') : '',
    toYear: endYear ? moment(endYear).utcOffset(0, true).format('YYYY') : '',
    states: [],
    legalForms: [],
    dateRange: currentDateIndex,
    inactiveStatus: process.env.NEXT_PUBLIC_CHAMBER_I18N_ID.split(', '),
    codes: finalCode,
    codeKAD: process.env.NEXT_PUBLIC_CODING_SYSTEM,
    professionKind: process.env.NEXT_PUBLIC_KIND,
  });
  const [params, setParams] = useState({
    mode: 'date',
  });

  const dateYearOptions = dataTest.dateYearOptions;

  const [openDropdown, setOpenDropdown] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    if (selectedIndex == 1 || selectedIndex == 2) {
      handleDatesChange(selectedIndex);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    submitBodyInsight();
    return () => {
      setData([]);
      setBody([]);
    };
  }, []);

  useEffect(async () => {
    body.dateRange = currentDateIndex;
    await getData(params, body);
    return () => {
      setData([]);
    };
  }, [currentDateIndex]);

  useEffect(() => {
    if (!checked) {
      setCompareStartDate(undefined);
      setCompareEndDate(undefined);
    }
  }, [checked]);

  const handleDatesChange = (index) => {
    let curStartDate;
    let curEndDate;
    let diff;
    let curCompareEndDate;
    let curCompareStartDate;

    switch (index) {
      case 1:
        curStartDate = moment(startDate, 'DD-MM-YYYY');
        curEndDate = moment(endDate, 'DD-MM-YYYY');
        diff = curEndDate.diff(curStartDate, 'days');
        curCompareEndDate = moment(startDate).subtract(1, 'days');
        curCompareStartDate = moment(curCompareEndDate).subtract(diff, 'days');
        break;
      case 2:
        curStartDate = moment(startDate, 'DD-MM-YYYY');
        curEndDate = moment(endDate, 'DD-MM-YYYY');
        curCompareEndDate = moment(endDate).subtract(1, 'year');
        curCompareStartDate = moment(startDate).subtract(1, 'year');
        break;
    }
    setCompareStartDate(moment(curCompareStartDate).toDate());
    setCompareEndDate(moment(curCompareEndDate).toDate());
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpenDropdown(false);

    switch (index) {
      case 0:
        setChecked(false);
        break;
      case 1:
        setChecked(true);
        handleDatesChange(index);
        setDisabledDates(true);
        break;
      case 2:
        setChecked(true);
        handleDatesChange(index);
        setDisabledDates(true);
        break;
      case 3:
        setChecked(true);
        setDisabledDates(false);
        break;
    }
    if (index !== 0) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  };

  const handleToggle = () => {
    setOpenDropdown((prevOpen) => !prevOpen);
  };

  const handleCloseDropdown = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenDropdown(false);
  };

  const handleState = (val) => {
    setState(val);
  };

  const handleLegalForm = (val) => {
    setLegalForm(val);
  };

  const handleCode = (array) => {
    let IDs = [];
    setCodeObject(array);
    array.map((code) => IDs.push(code.id.toString()));
    setCode(IDs);
  };

  const submitFilters = async () => {
    handleClose();
    setExpanded('panel1');
    setFinalStatesArray(state);
    setFinalLegalFormsArray(legalForm);
    setFinalCode(code);
    setFinalCodesArray(codeObject);
  };

  const getData = async (params, body) => {
    try {
      setLoadingState(true);
      const res = await getEstablishments(
        'POST',
        '/establishments/all',
        params,
        body
      );
      if (typeof res === 'number' || !res) {
        setOpenModal(true);
      } else {
        setData(res);
        setLoadingState(false);
      }
    } catch (err) {
      console.error(err);
      setOpenModal(true);
    }
  };

  const handleType = (event) => {
    setType(event.target.value);
  };

  const handleStartDate = (data) => {
    if (data) {
      setStartDate(data);
    } else {
      setStartDate(undefined);
    }
  };

  const handleEndDate = (data) => {
    if (data) {
      setEndDate(data);
    } else {
      setEndDate(undefined);
    }
  };

  const handleCompareStartDate = (data) => {
    if (data) {
      setCompareStartDate(data);
    } else {
      setCompareStartDate(undefined);
    }
  };

  const handleCompareEndDate = (data) => {
    if (data) {
      setCompareEndDate(data);
    } else {
      setCompareEndDate(undefined);
    }
  };

  const handleErrorOnStartYear = (error) => {
    if (error.length == 0) {
      setErrorOnStartYear(false);
    } else {
      setErrorOnStartYear(true);
    }
    handleErrorOnYears();
  };

  const handleErrorOnEndYear = (error) => {
    if (error.length == 0) {
      setErrorOnEndYear(false);
    } else {
      setErrorOnEndYear(true);
    }
    handleErrorOnYears();
  };

  const handleErrorOnYears = () => {
    if (errorOnEndYear || errorOnStartYear) {
      setErrorOnYears(true);
    } else {
      setErrorOnYears(false);
    }
  };

  const searchValidation = async () => {
    let varRequiredYearsFlag = true;
    let varRequiredDatesFlag = true;
    let varDateYearsSelected;
    let varCurDateIndex = currentDateIndex;

    if (selectDatesYearsIndex === 0) {
      varDateYearsSelected = true;
      if (!startDate || !endDate) {
        varRequiredDatesFlag = true;
        await setRequiredDatesFlag(varRequiredDatesFlag);
      } else {
        varRequiredDatesFlag = false;
        await setRequiredDatesFlag(varRequiredDatesFlag);
      }
    } else if (selectDatesYearsIndex === 1) {
      varDateYearsSelected = false;
      if (!startYear || !endYear || errorOnYears) {
        varRequiredYearsFlag = true;
        await setRequiredYearsFlag(varRequiredYearsFlag);
      } else {
        varRequiredYearsFlag = false;
        await setRequiredYearsFlag(varRequiredYearsFlag);
      }
      if (varCurDateIndex < 2) {
        varCurDateIndex = 2;
      }
    }
    setDateYearsSelected(varDateYearsSelected);

    switch (selectedIndex) {
      case 0:
        break;
      case 1:
        setFreeRangeCompare(false);
        break;
      case 2:
        setFreeRangeCompare(false);
        break;
      case 3:
        setFreeRangeCompare(true);
        break;
    }

    switch (type) {
      case 1:
        setRegDelCompareType(false);
        break;
      case 2:
        setRegDelCompareType(false);
        break;
      case 3:
        setRegDelCompareType(true);
        setFreeRangeCompare(false);
        break;
    }

    return {
      varRequiredYearsFlag,
      varRequiredDatesFlag,
      varDateYearsSelected,
      varCurDateIndex,
    };
  };

  const submitBodyInsight = async () => {
    let values = searchValidation();
    let varRequiredDatesFlag = (await values).varRequiredDatesFlag;
    let varRequiredYearsFlag = (await values).varRequiredYearsFlag;
    let varDateYearsSelected = (await values).varDateYearsSelected;

    if (!varRequiredYearsFlag || !varRequiredDatesFlag) {
      let bodyInsight = {
        startDate: varDateYearsSelected
          ? moment(startDate).utcOffset(0, true).format('YYYY-MM-DD')
          : moment(startYear).utcOffset(0, true).startOf('year').format('YYYY-MM-DD'),
        endDate: varDateYearsSelected
          ? moment(endDate).utcOffset(0, true).format('YYYY-MM-DD')
          : moment(endYear).utcOffset(0, true).endOf('year').format('YYYY-MM-DD'),
        states: finalStatesArray,
        legalForms: finalLegalFormsArray,
        codes: finalCode,
        inactiveStatus: process.env.NEXT_PUBLIC_CHAMBER_I18N_ID.split(', '),
        codeKAD: process.env.NEXT_PUBLIC_CODING_SYSTEM,
        professionKind: process.env.NEXT_PUBLIC_KIND,
      };
      setBodyInsight(bodyInsight);
      submitCompareBodyInsight();
    }
  };

  const submitCompareBodyInsight = async () => {
    let values = searchValidation();
    let varRequiredDatesFlag = (await values).varRequiredDatesFlag;
    let varRequiredYearsFlag = (await values).varRequiredYearsFlag;
    let varDateYearsSelected = (await values).varDateYearsSelected;
    let varCompare;

    if (
      selectDatesYearsIndex === 1 ||
      !compareStartDate ||
      !compareEndDate ||
      type === 3
    ) {
      varCompare = false;
    } else {
      switch (selectedIndex) {
        case 0:
          varCompare = false;
          break;
        case 1:
          varCompare = true;
          break;
        case 2:
          varCompare = true;
          break;
        case 3:
          varCompare = true;
          break;
      }
    }
    if (
      (!varRequiredYearsFlag || !varRequiredDatesFlag) &&
      compareEndDate &&
      compareStartDate &&
      varCompare
    ) {
      let bodyInsight = {
        startDate: varDateYearsSelected
          ? moment(compareStartDate).utcOffset(0, true).format('YYYY-MM-DD')
          : moment(startYear).utcOffset(0, true).startOf('year').format('YYYY-MM-DD'),
        endDate: varDateYearsSelected
          ? moment(compareEndDate).utcOffset(0, true).format('YYYY-MM-DD')
          : moment(endYear).endOf('year').format('YYYY-MM-DD'),
        states: finalStatesArray,
        legalForms: finalLegalFormsArray,
        codes: finalCode,
        inactiveStatus: process.env.NEXT_PUBLIC_CHAMBER_I18N_ID.split(', '),
        codeKAD: process.env.NEXT_PUBLIC_CODING_SYSTEM,
        professionKind: process.env.NEXT_PUBLIC_KIND,
      };
      setCompareBodyInsight(bodyInsight);
    }
    setCompare(varCompare);
  };

  const submitSearch = async () => {
    let values = searchValidation();
    let varRequiredDatesFlag = (await values).varRequiredDatesFlag;
    let varRequiredYearsFlag = (await values).varRequiredYearsFlag;
    let varDateYearsSelected = (await values).varDateYearsSelected;
    let varCurDateIndex = (await values).varCurDateIndex;

    if (!varRequiredYearsFlag || !varRequiredDatesFlag) {
      let paramsData = await { mode: varDateYearsSelected ? 'date' : 'year' };
      let bodyData = await {
        type: type,
        startDate: startDate ? moment(startDate).utcOffset(0, true).format('YYYY-MM-DD') : '',
        endDate: endDate ? moment(endDate).utcOffset(0, true).format('YYYY-MM-DD') : '',
        compareStartDate: compareStartDate
          ? moment(compareStartDate).utcOffset(0, true).format('YYYY-MM-DD')
          : '',
        compareEndDate: compareEndDate
          ? moment(compareEndDate).utcOffset(0, true).format('YYYY-MM-DD')
          : '',
        fromYear: startYear ? moment(startYear).utcOffset(0, true).format('YYYY') : '',
        toYear: endYear ? moment(endYear).utcOffset(0, true).format('YYYY') : '',
        states: finalStatesArray,
        legalForms: finalLegalFormsArray,
        codes: finalCode,
        dateRange: varCurDateIndex,
        inactiveStatus: process.env.NEXT_PUBLIC_CHAMBER_I18N_ID.split(', '),
        codeKAD: process.env.NEXT_PUBLIC_CODING_SYSTEM,
        professionKind: process.env.NEXT_PUBLIC_KIND,
      };
      await getData(paramsData, bodyData);
      setBody(bodyData);
      setParams(paramsData);
      setCurrentDateIndex(varCurDateIndex);
    }
  };

  const handleOption = (event, newOption) => {
    if (newOption !== null) {
      setSelectDatesYearsIndex(newOption);
    }
  };

  const handleExpanded = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (status, id) => () => {
    let array;
    let arrayObject;

    switch (status) {
      case 0:
        array = finalStatesArray.filter((state) => state !== id);
        setFinalStatesArray(array);
        setState(array);
        break;
      case 1:
        array = finalLegalFormsArray.filter((legalForm) => legalForm !== id);
        setFinalLegalFormsArray(array);
        setLegalForm(array);
        break;
      case 2:
        array = finalCode.filter((code) => code !== id);
        arrayObject = finalCodesArray.filter(
          (codeObject) => codeObject.id !== id
        );
        setFinalCodesArray(arrayObject);
        setCodeObject(arrayObject);
        setCode(array);
        setFinalCode(array);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Grid item xs={12} className={classes.titleButton}>
        <div style={{ display: 'contents' }}>
          <Typography
            component="h6"
            variant="h6"
            colSpan={6}
            className={classes.typographyTitle}
          >
            {title}
          </Typography>
        </div>
        <ModalDemography
          open={open}
          submitFilters={submitFilters}
          handleClose={handleClose}
          handleLegalForm={handleLegalForm}
          handleState={handleState}
          legalForm={legalForm}
          state={state}
          legalForms={legalForms}
          states={states}
          type={type}
          types={types}
          codeObject={codeObject}
          codes={codes}
          handleCode={handleCode}
        />
      </Grid>
      <Paper id="filter-paper" className={classes.paper}>
        <Grid
          item
          xs={12}
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <div id="select-dates-years" className={classes.dateYearComparison}>
            <Typography colSpan={6} className={classes.typographyDates}>
              {selectDatesYearsIndex === 1
                ? 'Επιλέξτε Εύρος Ετών:'
                : 'Επιλέξτε Εύρος Ημερομηνιών:'}
            </Typography>
            <ToggleButtonGroup
              size="small"
              id="date-years-button-group"
              value={selectDatesYearsIndex}
              exclusive
              onChange={handleOption}
              aria-label="date-years-button-group"
            >
              <ToggleButton
                value={0}
                aria-label="option 0"
                className={classes.selectedDatesYear}
              >
                <Typography variant="caption" gutterBottom>
                  {dateYearOptions[0].name}
                </Typography>
              </ToggleButton>
              <ToggleButton
                value={1}
                aria-label="option 1"
                className={classes.selectedDatesYear}
              >
                <Typography variant="caption" gutterBottom>
                  {dateYearOptions[1].name}
                </Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </Grid>
        <Grid id="type" item xs={12} className={classes.filters}>
          <FormControl className={classes.formControl} color="secondary">
            <InputLabel color="secondary" id="types">
              Τύπος
            </InputLabel>
            <Select
              labelId="select-types"
              id="select types"
              value={type}
              onChange={handleType}
            >
              {types.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid id="dates" item xs={12} className={classes.filters}>
          {selectDatesYearsIndex === 1 ? (
            <div id="years-wrapper" className={classes.yearsWrapper}>
              <Paper className={classes.paperYears}>
                <div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '0',
                        justifyContent: 'center',
                      }}
                    >
                      <DateYearPicker
                        handleStartDateChange={handleStartYear}
                        handleEndDateChange={handleEndYear}
                        selectedEndDate={endYear}
                        selectedStartDate={startYear}
                        handleErrorOnEndDate={handleErrorOnEndYear}
                        handleErrorOnStartDate={handleErrorOnStartYear}
                        disabledDates={false}
                        requiredDatesFlag={requiredYearsFlag}
                        errorOnStartDate={errorOnStartYear}
                        errorOnEndDate={errorOnEndYear}
                      />
                    </div>
                  </div>
                </div>
              </Paper>
            </div>
          ) : (
            <>
              <div className={classes.dates}>
                <div>Ημ/νία Εγγραφής/Διαγραφής </div>
                <DateRangePicker
                  placeholderFrom=" Από "
                  placeholderTo="Έως"
                  id="datepicker"
                  required={requiredDatesFlag}
                  startDate={startDate}
                  endDate={endDate}
                  handleStartDate={handleStartDate}
                  handleEndDate={handleEndDate}
                  width={isTablet ? '0px' : '100px'}
                />
              </div>
              {type !== 3 ? (
                <>
                  <div className={classes.dates}>
                    <Grid
                      container
                      alignItems="flex-end"
                      style={{ width: 'auto', height: 55 }}
                    >
                      <Grid item xs={12}>
                        <ButtonGroup
                          variant="contained"
                          color="primary"
                          ref={anchorRef}
                          aria-label="split button"
                        >
                          <Button style={{ textTransform: 'none' }}>
                            {options[selectedIndex]}
                          </Button>
                          <Button
                            color="primary"
                            size="small"
                            aria-controls={
                              openDropdown ? 'split-button-menu' : undefined
                            }
                            aria-expanded={openDropdown ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                          >
                            <ArrowDropDownIcon />
                          </Button>
                        </ButtonGroup>
                        <Popper
                          open={openDropdown}
                          anchorEl={anchorRef.current}
                          role={undefined}
                          transition
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin:
                                  placement === 'bottom'
                                    ? 'center top'
                                    : 'center bottom',
                              }}
                            >
                              <Paper style={{ zIndex: 0 }}>
                                <ClickAwayListener
                                  onClickAway={handleCloseDropdown}
                                >
                                  <MenuList id="split-button-menu">
                                    {options.map((option, index) => (
                                      <MenuItem
                                        key={option}
                                        selected={index === selectedIndex}
                                        onClick={(event) =>
                                          handleMenuItemClick(event, index)
                                        }
                                      >
                                        {option}
                                      </MenuItem>
                                    ))}
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      </Grid>
                    </Grid>
                  </div>
                  {checked ? (
                    <div className={classes.dates}>
                      <div>Ημ/νία Εγγραφής/Διαγραφής </div>
                      <DateRangePickerCompare
                        placeholderFrom=" Από "
                        placeholderTo="Έως"
                        id="datepicker-compare"
                        disabled={disabledDates}
                        required={false}
                        startDate={compareStartDate}
                        endDate={compareEndDate}
                        handleStartDate={handleCompareStartDate}
                        handleEndDate={handleCompareEndDate}
                        width={isTablet ? '0px' : '100px'}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </Grid>
        <Grid
          item
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'flex-start',
          }}
        >
          <div id="years-wrapper" className={classes.filterChip}>
            <Accordion
              square
              expanded={expanded === 'panel1'}
              onChange={handleExpanded('panel1')}
              className={classes.accordion}
            >
              <StyledBadge
                badgeContent={
                  finalStatesArray.length +
                  finalLegalFormsArray.length + finalCodesArray.length
                }
                color="primary"
              >
                <Chip
                  color="secondary"
                  label="Προσθήκη Φίλτρων"
                  icon={<AddCircleSharpIcon />}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleOpen();
                  }}
                  style={{ marginTop: "12px", marginLeft: "5px", marginBottom: "12px" }}
                />
              </StyledBadge>
              <AccordionSummary
                expandIcon={
                  finalStatesArray.length + finalCodesArray.length +
                    finalLegalFormsArray.length >
                    0 ? (
                    <ExpandMoreIcon />
                  ) : null
                }
                aria-controls="content"
                id="header"
                className={classes.accordionTitle}
              >
              </AccordionSummary>
              {finalStatesArray.map((value) =>
                states
                  .filter((option) => option.id == value)
                  .map((final) => (
                    <Chip
                      variant="outlined"
                      classes={{
                        label: classsesChip.colorChip1,
                        outlined: classsesChip.colorChipBorder1,
                        deleteIcon: classsesChip.colorChip1,
                      }}
                      key={final.id}
                      label={final.name}
                      className={classes.chip}
                      onDelete={handleDelete(0, final.id)}
                    />
                  ))
              )}
              {finalLegalFormsArray.map((value) =>
                legalForms
                  .filter((option) => option.id == value)
                  .map((final) => (
                    <Chip
                      variant="outlined"
                      classes={{
                        label: classsesChip.colorChip2,
                        outlined: classsesChip.colorChipBorder2,
                        deleteIcon: classsesChip.colorChip2,
                      }}
                      key={final.id}
                      label={final.name}
                      className={classes.chip}
                      onDelete={handleDelete(1, final.id)}
                    />
                  ))
              )}
              {finalCodesArray.map((final) => (
                <Chip
                  variant='outlined'
                  classes={{
                    label: classsesChip.colorChip4,
                    outlined: classsesChip.colorChipBorder4,
                    deleteIcon: classsesChip.colorChip4,
                  }}
                  key={final.id}
                  label={final.id}
                  className={classes.chip}
                  onDelete={handleDelete(2, final.id)}
                />
              ))}
            </Accordion>
          </div>
        </Grid>
        <Grid item className={classes.submitGrid}>
          <Button
            id="filter-button"
            type="submit"
            variant="outlined"
            className={classes.searchButton}
            onClick={() => {
              submitSearch();
              submitBodyInsight();
            }}
          >
            ΑΝΑΖΗΤΗΣΗ
          </Button>
        </Grid>
      </Paper>
    </>
  );
};

export default FiltersEstablishments;
