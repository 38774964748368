import { Typography } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Chip from '@material-ui/core/Chip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import Grow from '@material-ui/core/Grow';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import TextField from '@material-ui/core/TextField';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Autocomplete } from '@material-ui/lab';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { CompareHandling } from '../../src/context/CompareContext';
import { DataHandling } from '../../src/context/DataContext';
import { DateIndexHandling } from '../../src/context/DateIndexContext';
import { DateYearsHandling } from '../../src/context/DateYearsContext';
import { ErrorHandling } from '../../src/context/ErrorContext';
import { FreeRangeCompareHandling } from '../../src/context/FreeRangeCompareContext';
import { LoadingHandling } from '../../src/context/LoadingContext';
import { TypeHandling } from '../../src/context/TypeContext';
import dataTest from '../../src/dataTest/dataTest.json';
import { getEstablishments } from '../../src/requests/client/getEstablishments';
import useStylesChip from '../../src/theme/stylesChip';
import DateRangePickerCompareMobile from './DateRangePickerCompareMobile';
import DateRangePickerMobile from './DateRangePickerMobile';
import DateYearPickerMobile from './DateYearPickerMobile';
import { ListboxComponent } from '../assets/Listbox';

const options = [
  'Χωρίς σύγκριση',
  'Προηγούμενο διάστημα',
  'Προηγούμενο έτος',
  'Ελεύθερο διάστημα',
];

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginLeft: theme.spacing(1),
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: '25ch',
    },
  },
  accordion: {
    marginTop: theme.spacing(1),
    boxShadow: 'none',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: '5px',
    borderColor: fade(theme.palette.secondary.main, 0.15),
  },
  paper: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  accordionTitle: {
    maxHeight: 60,
    paddingLeft: 0,
    marginLeft: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    width: 160,
  },
  headingTitle: {
    fontSize: '1.1rem',
    fontWeight: 450,
    width: 160,
  },
  paperYears: {
    display: 'inline-flex',
    textAlign: 'center',
    boxShadow: 'none',
    alignItems: 'baseline',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    width: '100%',
  },
  typographyTitle: {
    display: 'flex',
    flex: 1,
  },
  typographyDates: {
    display: 'flex',
    flex: 1,
    margin: theme.spacing(1),
    width: '100%',
  },
  titleButton: {
    justifyContent: 'flex-start',
    display: 'flex',
    verticalAlign: 'top',
    width: '100%',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    alignContent: 'stretch',
  },
  selectedDatesYear: {
    paddinTop: 0,
    paddingBottom: 0,
    color: theme.palette.disabled,
    background: theme.palette.primary.main,
    '&.Mui-selected': {
      color: theme.palette.secondary.main,
      background: theme.palette.primary.main,
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: theme.palette.secondary.main,
    },
  },
  dateYearComparison: {
    marginLeft: 210,
    display: 'flex',
    flex: '1',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },
  searchButton: {
    marginTop: theme.spacing(1),
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.secondary.main,
    width: '100%',
    borderRadius: 0,
    '&:hover': {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.primary.main,
    },
  },
  filters: {
    display: 'inline-flex',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      display: 'contents',
    },
  },
  formControl: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    minWidth: 270,
    width: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 260,
  },
  dates: {
    margin: theme.spacing(2),
  },
  checkboxCompare: {
    marginRight: theme.spacing(2),
    marginTop: theme.spacing(4),
    display: 'grid',
    width: 100,
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  checkbox: {
    justifySelf: 'center',
    padding: '0',
  },
  yearsWrapper: {
    margin: theme.spacing(3),
    display: 'flex',
    flex: '1',
    width: '100%',
  },
  menuItemRoot: {
    whiteSpace: 'pre-wrap',
    '&$menuItemSelected, &$menuItemSelected:hover': {
      // backgroundColor: theme.palette.secondary.main,
      // color: theme.palette.primary.main,
      /**
       * TODO: may apply this minimal styling and remove
       * all colors from selected, hover
       */
      fontWeight: 430,
    },
    '&$menuItemSelected:hover': {
      // backgroundColor: theme.palette.secondary.main,
      // color: theme.palette.primary.main,
      fontWeight: 400,
    },
  },
  menuItemSelected: {},
  chip: {
    margin: theme.spacing(0.5),
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexWrap: 'nowrap',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const FiltersEstablishmentsMobile = (props) => {
  const classes = useStyles();
  const classesChip = useStylesChip();
  const { title, types, states, legalForms, codes } = props;
  const [type, setType] = useState(types[0].id);

  const [requiredYearsFlag, setRequiredYearsFlag] = useState(false);
  const [requiredDatesFlag, setRequiredDatesFlag] = useState(false);

  const [startDate, setStartDate] = useState(
    moment().utcOffset(0, true).startOf('month').toDate()
  );
  const [endDate, setEndDate] = useState(moment().utcOffset(0, true).toDate());
  const [startYear, handleStartYear] = useState(null);
  const [endYear, handleEndYear] = useState(null);

  const [compareStartDate, setCompareStartDate] = useState(undefined);
  const [compareEndDate, setCompareEndDate] = useState(undefined);

  const [checked, setChecked] = useState(false);
  const [state, setState] = useState([]);
  const [legalForm, setLegalForm] = useState([]);
  const [openCodes, setOpenCodes] = useState(false);
  const [codeIDs, setCodeIDs] = useState([]);
  const [disabledDates, setDisabledDates] = useState(false);
  const [selectDatesYearsIndex, setSelectDatesYearsIndex] = useState(0);
  const [errorOnStartYear, setErrorOnStartYear] = useState(false);
  const [errorOnEndYear, setErrorOnEndYear] = useState(false);
  const [errorOnYears, setErrorOnYears] = useState(false);
  const { setDateYearsSelected } = useContext(DateYearsHandling);
  const { setOpenModal } = useContext(ErrorHandling);
  const { currentDateIndex, setCurrentDateIndex } =
    useContext(DateIndexHandling);
  const { setData } = useContext(DataHandling);
  const { setLoadingState } = useContext(LoadingHandling);

  const [expanded, setExpanded] = useState('panel1');

  const [params, setParams] = useState({ mode: 'date' });
  const [body, setBody] = useState({
    type: type,
    startDate: moment(startDate).utcOffset(0, true).format('YYYY-MM-DD'),
    endDate: moment(endDate).utcOffset(0, true).format('YYYY-MM-DD'),
    compareStartDate: compareStartDate
      ? moment(compareStartDate).utcOffset(0, true).format('YYYY-MM-DD')
      : '',
    compareEndDate: compareEndDate
      ? moment(compareEndDate).utcOffset(0, true).format('YYYY-MM-DD')
      : '',
    fromYear: startYear ? moment(startYear).utcOffset(0, true).format('YYYY') : '',
    toYear: endYear ? moment(endYear).utcOffset(0, true).format('YYYY') : '',
    states: state,
    legalForms: legalForm,
    codes: codeIDs,
    dateRange: currentDateIndex,
    inactiveStatus: process.env.NEXT_PUBLIC_CHAMBER_I18N_ID.split(', '),
    codeKAD: process.env.NEXT_PUBLIC_CODING_SYSTEM,
    professionKind: process.env.NEXT_PUBLIC_KIND
  });
  const { setFreeRangeCompare } = useContext(FreeRangeCompareHandling);
  const { setCompare } = useContext(CompareHandling);

  const { setRegDelCompareType } = useContext(TypeHandling);

  const dateYearOptions = dataTest.dateYearOptions;

  const [openDropdown, setOpenDropdown] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleState = (val) => {
    setState(val.target.value);
  };

  const handleLegalForm = (val) => {
    setLegalForm(val.target.value);
  };
    
  const handleCode = (array) => {
    let IDs = [];
    array.map((code) => IDs.push(code.id));
    setCodeIDs(IDs);
  };

  const handleExpanded = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const getData = async (params, body) => {
    try {
      setLoadingState(true);
      const res = await getEstablishments(
        'POST',
        '/establishments/all',
        params,
        body
      );
      if (typeof res === 'number' || !res) {
        setOpenModal(true);
      } else {
        setData(res);
        setLoadingState(false);
      }
    } catch (err) {
      console.error(err);
      setOpenModal(true);
    }
  };

  const handleType = (event) => {
    setType(event.target.value);
  };

  useEffect(async () => {
    body.dateRange = currentDateIndex;
    await getData(params, body);
    return () => {
      setData([]);
    };
  }, [currentDateIndex]);

  useEffect(() => {
    if (!checked) {
      setCompareStartDate(undefined);
      setCompareEndDate(undefined);
    }
  }),
    [checked];

  const handleDatesChange = (index) => {
    let curStartDate;
    let curEndDate;
    let diff;
    let curCompareEndDate;
    let curCompareStartDate;
    switch (index) {
      case 1:
        curStartDate = moment(startDate, 'DD-MM-YYYY');
        curEndDate = moment(endDate, 'DD-MM-YYYY');
        diff = curEndDate.diff(curStartDate, 'days');
        curCompareEndDate = moment(startDate).subtract(1, 'days');
        curCompareStartDate = moment(curCompareEndDate).subtract(diff, 'days');
        break;
      case 2:
        curStartDate = moment(startDate, 'DD-MM-YYYY');
        curEndDate = moment(endDate, 'DD-MM-YYYY');
        curCompareEndDate = moment(endDate).subtract(1, 'year');
        curCompareStartDate = moment(startDate).subtract(1, 'year');
        break;
      case 3:
        break;
    }
    setCompareStartDate(moment(curCompareStartDate).toDate());
    setCompareEndDate(moment(curCompareEndDate).toDate());
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpenDropdown(false);

    switch (index) {
      case 0:
        setChecked(false);
        break;
      case 1:
        setChecked(true);
        handleDatesChange(index);
        setDisabledDates(true);
        break;
      case 2:
        setChecked(true);
        handleDatesChange(index);
        setDisabledDates(true);
        break;
      case 3:
        setChecked(true);
        setDisabledDates(false);
        break;
    }
    if (index !== 0) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  };

  const handleToggle = () => {
    setOpenDropdown((prevOpen) => !prevOpen);
  };

  const handleCloseDropdown = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpenDropdown(false);
  };

  const handleStartDate = (data) => {
    if (data) {
      setStartDate(data);
    } else {
      setStartDate('');
    }
  };

  const handleEndDate = (data) => {
    if (data) {
      setEndDate(data);
    } else {
      setEndDate(undefined);
    }
  };

  const handleCompareStartDate = (data) => {
    if (data) {
      setCompareStartDate(data);
    } else {
      setCompareStartDate(undefined);
    }
  };

  const handleCompareEndDate = (data) => {
    if (data) {
      setCompareEndDate(data);
    } else {
      setCompareEndDate(undefined);
    }
  };

  const handleErrorOnStartYear = (error) => {
    if (error.length == 0) {
      setErrorOnStartYear(false);
    } else {
      setErrorOnStartYear(true);
    }
    handleErrorOnYears();
  };

  const handleErrorOnEndYear = (error) => {
    if (error.length == 0) {
      setErrorOnEndYear(false);
    } else {
      setErrorOnEndYear(true);
    }
    handleErrorOnYears();
  };

  const handleErrorOnYears = () => {
    if (errorOnEndYear || errorOnStartYear) {
      setErrorOnYears(true);
    } else {
      setErrorOnYears(false);
    }
  };

  const handleDelete = (index, id) => () => {
    let array;
    switch (index) {
      case 0:
        array = state.filter((option) => option !== id);
        setState(array);
        break;
      case 1:
        array = legalForm.filter((option) => option !== id);
        setLegalForm(array);
        break;
      default:
        break;
    }
  };

  const searchValidation = async () => {
    let varRequiredYearsFlag = true;
    let varRequiredDatesFlag = true;
    let varDateYearsSelected;
    let varCurDateIndex = currentDateIndex;

    if (selectDatesYearsIndex === 0) {
      varDateYearsSelected = true;
      if (!startDate || !endDate) {
        varRequiredDatesFlag = true;
        await setRequiredDatesFlag(varRequiredDatesFlag);
      } else {
        varRequiredDatesFlag = false;
        await setRequiredDatesFlag(varRequiredDatesFlag);
      }
    } else if (selectDatesYearsIndex === 1) {
      varDateYearsSelected = false;
      if (!startYear || !endYear || errorOnYears) {
        varRequiredYearsFlag = true;
        await setRequiredYearsFlag(varRequiredYearsFlag);
      } else {
        varRequiredYearsFlag = false;
        await setRequiredYearsFlag(varRequiredYearsFlag);
      }
      if (varCurDateIndex < 2) {
        varCurDateIndex = 2;
      }
    }
    setDateYearsSelected(varDateYearsSelected);

    switch (selectedIndex) {
      case 0:
        break;
      case 1:
        setFreeRangeCompare(false);
        break;
      case 2:
        setFreeRangeCompare(false);
        break;
      case 3:
        setFreeRangeCompare(true);
        break;
    }
    
    switch (type) {
      case 1:
        setRegDelCompareType(false);
        break;
      case 2:
        setRegDelCompareType(false);
        break;
      case 3:
        setRegDelCompareType(true);
        setFreeRangeCompare(false);
        break;
    }

    return {
      varRequiredYearsFlag,
      varRequiredDatesFlag,
      varDateYearsSelected,
      varCurDateIndex,
    };
  };

  const submitSearch = async () => {
    let values = searchValidation();
    let varRequiredDatesFlag = (await values).varRequiredDatesFlag;
    let varRequiredYearsFlag = (await values).varRequiredYearsFlag;
    let varDateYearsSelected = (await values).varDateYearsSelected;
    let varCurDateIndex = (await values).varCurDateIndex;

    if (!varRequiredYearsFlag || !varRequiredDatesFlag) {
      let paramsData = await { mode: varDateYearsSelected ? 'date' : 'year' };

      let bodyData = await {
        type: type,
        startDate: moment(startDate).utcOffset(0, true).format('YYYY-MM-DD'),
        endDate: moment(endDate).utcOffset(0, true).format('YYYY-MM-DD'),
        compareStartDate: compareStartDate
          ? moment(compareStartDate).utcOffset(0, true).format('YYYY-MM-DD')
          : '',
        compareEndDate: compareEndDate
          ? moment(compareEndDate).utcOffset(0, true).format('YYYY-MM-DD')
          : '',
        fromYear: startYear ? moment(startYear).utcOffset(0, true).format('YYYY') : '',
        toYear: endYear ? moment(endYear).utcOffset(0, true).format('YYYY') : '',
        states: state,
        legalForms: legalForm,
        codes: codeIDs,
        dateRange: currentDateIndex,
        inactiveStatus: process.env.NEXT_PUBLIC_CHAMBER_I18N_ID.split(', '),
        codeKAD: process.env.NEXT_PUBLIC_CODING_SYSTEM,
        professionKind: process.env.NEXT_PUBLIC_KIND
      };
      await getData(paramsData, bodyData);
      setBody(bodyData);
      setParams(paramsData);
      setCurrentDateIndex(varCurDateIndex);
    }

    let varCompare;

    if (selectDatesYearsIndex === 1 || !compareStartDate || !compareEndDate) {
      varCompare = false;
    } else {
      switch (selectedIndex) {
        case 0:
          varCompare = false;
          break;
        case 1:
          varCompare = true;
          break;
        case 2:
          varCompare = true;
          break;
        case 3:
          varCompare = true;
          break;
      }
    }
    setCompare(varCompare);
  };

  const handleOption = (event, newOption) => {
    if (newOption !== null) {
      setSelectDatesYearsIndex(newOption);
    }
  };

  return (
    <>
      <Grid item xs={12} className={classes.titleButton}>
        <div style={{ display: 'contents' }}>
          <Typography
            component="h6"
            variant="h6"
            colSpan={6}
            className={classes.typographyTitle}
          >
            {title}
          </Typography>
        </div>
      </Grid>
      <Accordion
        square
        id="accordion"
        expanded={expanded === 'panel1'}
        onChange={handleExpanded('panel1')}
        className={classes.accordion}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="content"
          id="header"
          className={classes.accordionTitle}
        >
          <Typography className={classes.headingTitle}>
            Επιλογή Φίλτρων
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.paper}>
          <Typography colSpan={6} className={classes.typographyDates}>
            {selectDatesYearsIndex === 1
              ? 'Επιλέξτε Εύρος Ετών:'
              : 'Επιλέξτε Εύρος Ημερομηνιών:'}
          </Typography>
          <Grid
            item
            xs={12}
            style={{
              display: 'inline-flex',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <div id="select-dates-years" className={classes.dateYearComparison}>
              <ToggleButtonGroup
                size="small"
                id="date-years-button-group"
                value={selectDatesYearsIndex}
                exclusive
                onChange={handleOption}
                aria-label="date-years-button-group"
              >
                <ToggleButton
                  value={0}
                  aria-label="option 0"
                  className={classes.selectedDatesYear}
                >
                  <Typography variant="caption" gutterBottom>
                    {dateYearOptions[0].name}
                  </Typography>
                </ToggleButton>
                <ToggleButton
                  value={1}
                  aria-label="option 1"
                  className={classes.selectedDatesYear}
                >
                  <Typography variant="caption" gutterBottom>
                    {dateYearOptions[1].name}
                  </Typography>
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </Grid>
          <Grid id="type-dates" item xs={12} className={classes.filters}>
            {selectDatesYearsIndex === 1 ? (
              <div id="years-wrapper" className={classes.yearsWrapper}>
                <Paper className={classes.paperYears}>
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: '0',
                          justifyContent: 'center',
                        }}
                      >
                        <DateYearPickerMobile
                          handleStartDateChange={handleStartYear}
                          handleEndDateChange={handleEndYear}
                          selectedEndDate={endYear}
                          selectedStartDate={startYear}
                          handleErrorOnEndDate={handleErrorOnEndYear}
                          handleErrorOnStartDate={handleErrorOnStartYear}
                          disabledDates={false}
                          requiredDatesFlag={requiredYearsFlag}
                          errorOnStartDate={errorOnStartYear}
                          errorOnEndDate={errorOnEndYear}
                        />
                      </div>
                    </div>
                  </div>
                </Paper>
              </div>
            ) : (
              <>
                <div className={classes.dates}>
                  <div>Ημ/νία Εγγραφής/Διαγραφής </div>
                  <DateRangePickerMobile
                    placeholderFrom="Από "
                    placeholderTo=" Έως "
                    id="datepicker"
                    disabled={disabledDates}
                    required={requiredDatesFlag}
                    startDate={startDate}
                    endDate={endDate}
                    handleStartDate={handleStartDate}
                    handleEndDate={handleEndDate}
                  />
                </div>
                {type !== 3 ? (
                  <>
                    <Grid container direction="column" alignItems="center">
                      <Grid item xs={12}>
                        <ButtonGroup
                          variant="contained"
                          color="primary"
                          ref={anchorRef}
                          aria-label="split button"
                        >
                          <Button style={{ textTransform: 'none' }}>
                            {options[selectedIndex]}
                          </Button>
                          <Button
                            color="primary"
                            size="small"
                            aria-controls={
                              openDropdown ? 'split-button-menu' : undefined
                            }
                            aria-expanded={openDropdown ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={handleToggle}
                          >
                            <ArrowDropDownIcon />
                          </Button>
                        </ButtonGroup>
                        <Popper
                          open={openDropdown}
                          anchorEl={anchorRef.current}
                          role={undefined}
                          transition
                        >
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              style={{
                                transformOrigin:
                                  placement === 'bottom'
                                    ? 'center top'
                                    : 'center bottom',
                              }}
                            >
                              <Paper style={{ zIndex: 0 }}>
                                <ClickAwayListener
                                  onClickAway={handleCloseDropdown}
                                >
                                  <MenuList id="split-button-menu">
                                    {options.map((option, index) => (
                                      <MenuItem
                                        key={option}
                                        selected={index === selectedIndex}
                                        onClick={(event) =>
                                          handleMenuItemClick(event, index)
                                        }
                                      >
                                        {option}
                                      </MenuItem>
                                    ))}
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      </Grid>
                    </Grid>
                    {checked ? (
                      <div className={classes.dates}>
                        <div>Ημ/νία Εγγραφής/Διαγραφής </div>
                        <DateRangePickerCompareMobile
                          placeholderFrom="Από "
                          placeholderTo=" Έως "
                          id="datepicker-compare"
                          disabled={disabledDates}
                          required={false}
                          startDate={compareStartDate}
                          endDate={compareEndDate}
                          handleStartDate={handleCompareStartDate}
                          handleEndDate={handleCompareEndDate}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </Grid>
          <Grid
            item
            style={{
              alignItems: 'center',
              width: '100%',
              justifyContent: 'flex-start',
            }}
          >
            <FormControl className={classes.formControl} color="secondary">
              <InputLabel color="secondary" id="types">
                Τύπος
              </InputLabel>
              <Select
                labelId="select-types"
                id="select types"
                value={type}
                onChange={handleType}
              >
                {types.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl} color="secondary">
              <InputLabel id="state-label">Κατάσταση</InputLabel>
              <Select
                labelId="select-states"
                id="select-states"
                multiple
                value={state}
                onChange={handleState}
                input={<Input id="select-state-chip" />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) =>
                      states
                        .filter((option) => option.id === value)
                        .map((final) => (
                          <Chip
                            variant="outlined"
                            key={final.id}
                            label={final.name}
                            className={classes.chip}
                            classes={{
                              label: classesChip.colorChip1,
                              root: classesChip.colorChipBorder1,
                              deleteIcon: classesChip.colorChip1,
                            }}
                            onMouseDown={(event) => {
                              event.stopPropagation();
                            }}
                            onDelete={handleDelete(0, final.id)}
                          />
                        ))
                    )}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {states.map((option) => (
                  <MenuItem
                    classes={{
                      root: classes.menuItemRoot,
                      selected: classes.menuItemSelected,
                    }}
                    key={option.id}
                    value={option.id}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl} color="secondary">
              <InputLabel id="legal-forms-label">Νομικές Μορφές</InputLabel>
              <Select
                labelId="select-legal-forms"
                id="select-legal-forms"
                multiple
                value={legalForm}
                onChange={handleLegalForm}
                input={<Input id="select-legal-forms-chip" />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) =>
                      legalForms
                        .filter((option) => option.id === value)
                        .map((final) => (
                          <Chip
                            variant="outlined"
                            key={final.id}
                            label={final.name}
                            className={classes.chip}
                            classes={{
                              label: classesChip.colorChip2,
                              root: classesChip.colorChipBorder2,
                              deleteIcon: classesChip.colorChip2,
                            }}
                            onMouseDown={(event) => {
                              event.stopPropagation();
                            }}
                            onDelete={handleDelete(1, final.id)}
                          />
                        ))
                    )}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {legalForms.map((option) => (
                  <MenuItem
                    classes={{
                      root: classes.menuItemRoot,
                      selected: classes.menuItemSelected,
                    }}
                    key={option.id}
                    value={option.id}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Autocomplete
              classes={{
                option: classes.option,
                listbox: classes.listbox,
                tag: classesChip.colorChipAutocomplete4,
              }}
              disableCloseOnSelect
              ListboxComponent={ListboxComponent}
              multiple
              disableListWrap
              id='codes-autocomplete'
              className={classes.formControl}
              open={openCodes}
              onOpen={() => {
                setOpenCodes(true);
              }}
              onClose={() => {
                setOpenCodes(false);
              }}
              getOptionSelected={(option, value) =>
                option.name === value.name
              }
              getOptionLabel={(option) => option.name}
              options={codes}
              noOptionsText='Κανένα αποτέλεσμα'
              onChange={(event, newValue) => {
                handleCode(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Επιλέξτε Δραστηριότητες'
                  color='secondary'
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
            <Button
              id="filter-button"
              type="submit"
              variant="outlined"
              className={classes.searchButton}
              size="small"
              onClick={submitSearch}
            >
              ΑΝΑΖΗΤΗΣΗ
            </Button>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default FiltersEstablishmentsMobile;
