import { Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import GridOnIcon from "@material-ui/icons/GridOn";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import PrintIcon from "@material-ui/icons/Print";
import moment from "moment";
import "moment/locale/el";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import ReactToPrint from "react-to-print";
import { DataHandling } from "../../src/context/DataContext";
import { DateIndexHandling } from "../../src/context/DateIndexContext";
import { FreeRangeCompareHandling } from "../../src/context/FreeRangeCompareContext";
import { TypeHandling } from "../../src/context/TypeContext";
import ExportToCSV from "../tables/ExportToCSV";
import ExportToExcel from "../tables/ExportToExcel";
import generatePDF from "../tables/ExportToPDF";
import { format } from "d3-format";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "100%",
    height: "calc(100vh - 180px)",
    marginTop: theme.spacing(1),
  },
  paperForPrint: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginTop: theme.spacing(1),
  },
  title: {
    maxWidth: "100%",
    flex: "1 1 100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    flexWrap: "nowrap",
  },
  background: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
  },
  typographyTitle: {
    display: "flex",
    flex: 1,
  },
  titleButton: {
    justifyContent: "flex-start",
    display: "flex",
    verticalAlign: "top",
    width: "100%",
    flexWrap: "nowrap",
    alignItems: "stretch",
    alignContent: "stretch",
  },
  filterButton: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 0,
    textTransform: "none",
    "&:hover": {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
    },
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    width: 200,
    flex: 1,
    display: "flex",
  },
  accordion: {
    width: "100%",
  },
  fullTitle: {
    width: "100%",
    textAlign: "end",
    fontWeight: 500,
    fontSize: "1.05rem",
  },
}));

const EstablishmentsTableMobile = () => {
  const classes = useStyles();
  const { data } = useContext(DataHandling);
  const { currentDateIndex } = useContext(DateIndexHandling);
  const { freeRangeCompare } = useContext(FreeRangeCompareHandling);
  const [compare, setCompare] = useState();
  const [dataTable, setDataTable] = useState([]);
  const [dataFileTable, setDataFileTable] = useState([]);
  const [columns, setColumns] = useState([]);
  const [copied, setCopied] = useState(false);
  const [title, setTitle] = useState("");
  const componentRef = useRef();
  const { regDelCompareType } = useContext(TypeHandling);

  useEffect(() => {
    if (data && data !== undefined) {
      getData();
    }
    return () => {
      setDataTable([]);
    };
  }, [data, currentDateIndex]);

  const getData = async () => {
    try {
      if (!freeRangeCompare) {
        if (data.length > 1) {
          setCompare(true);

          var array = JSON.parse(JSON.stringify(data));

          if (array[0].length !== array[1].length) {
            if (array[1].length > array[0].length) {
              let diff = array[1].length - array[0].length;
              let diffVar;
              for (diffVar = 0; diffVar < diff; diffVar++) {
                array[1].pop();
              }
            }
            if (array[0].length > array[1].length) {
              let diff = array[0].length - array[1].length;
              let diffVar;
              for (diffVar = 0; diffVar < diff; diffVar++) {
                array[0].pop();
              }
            }
          }

          for (const i in array) {
            if (i == 0) {
              for (const j in array[i]) {
                array[i][j].change = array[0][j].y - array[1][j].y;
              }
            }
          }
          let tableArray = [];
          for (const i in array) {
            for (const j in array[i]) {
              tableArray[j] = [array[0][j], array[1][j]];
            }
          }
          setDataTable(tableArray);
          createDataFileTable(tableArray);
        } else {
          setCompare(false);
          setDataTable(data);
          createDataFileTable(data);
        }
      } else {
        if (data.length > 1) {
          setCompare(true);
          let table = [data[0]];

          data[1].map((entry) => table[0].push(entry));

          setDataTable(table);
          createDataFileTable(table);
        } else {
          setCompare(false);
          setDataTable(data);
          createDataFileTable(data);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const createDataFileTable = (tableArray) => {
    let arrayToExport = [];
    var array = JSON.parse(JSON.stringify(data));
    let dataFound = true;
    if (array.length <= 0 || array[0].length <= 0) {
      dataFound = false;
    }
    if (dataFound) {
      if (data.length > 1 && !freeRangeCompare) {
        let textPdf;
        if (regDelCompareType) {
          textPdf = "Αποτελέσματα Εγγραφών - Διαγραφών ("
            .concat(
              String(moment(array[0][0].x).locale("el").format("DD/MM/YYYY"))
            )
            .concat("-")
            .concat(
              String(
                moment(array[array.length - 1][0].x)
                  .locale("el")
                  .format("DD/MM/YYYY")
              )
            )
            .concat(")");
        } else {
          var pdfFromString = `${String(moment(array[0][0].x).locale("el").format("DD/MM/YYYY")) +
            "-" +
            String(moment(array[array.length - 1][0].x).format("DD/MM/YYYY"))
            }`;
          var pdfToString = `${String(moment(array[0][1].x).locale("el").format("DD/MM/YYYY")) +
            "-" +
            String(moment(array[array.length - 1][1].x).format("DD/MM/YYYY"))
            }`;
          textPdf = "Αποτελέσματα για "
            .concat(pdfFromString)
            .concat(" συγκριτικά με ")
            .concat(pdfToString);
        }
        for (const i in tableArray) {
          let dataToExport = {};
          if (regDelCompareType) {
            dataToExport[dateLabel()] = dateFormat(tableArray[i][0].x);
            dataToExport["Πλήθος Eγγραφών"] = tableArray[i][0].y;
            dataToExport["Πλήθος Διαγραφών"] = tableArray[i][1].y;
            dataToExport["Μεταβολή (Εγγραφές-Διαγραφές)"] =
              Number(tableArray[i][0].change);
            arrayToExport.push(dataToExport);
          } else {
            dataToExport[dateLabel()] = dateFormat(tableArray[i][0].x);
            dataToExport["Πλήθος"] = tableArray[i][0].y;
            dataToExport[dateComparisonLabel()] = dateFormat(
              tableArray[i][1].x
            );
            dataToExport["Πλήθος Σύγκρισης"] = tableArray[i][1].y;
            dataToExport["Μεταβολή"] = Number(tableArray[i][0].change);
            arrayToExport.push(dataToExport);
          }
        }
        let columnsArray = {};
        if (regDelCompareType) {
          columnsArray[dateLabel()] = dateLabel();
          columnsArray["Πλήθος Eγγραφών"] = "Πλήθος Eγγραφών";
          columnsArray["Πλήθος Διαγραφών"] = "Πλήθος Διαγραφών";
          columnsArray["Μεταβολή (Εγγραφές-Διαγραφές)"] =
            "Μεταβολή (Εγγραφές-Διαγραφές)";
        } else {
          columnsArray[dateLabel()] = dateLabel();
          columnsArray["Πλήθος"] = "Πλήθος";
          columnsArray[dateComparisonLabel()] = dateComparisonLabel();
          columnsArray["Πλήθος Σύγκρισης"] = "Πλήθος Σύγκρισης";
          columnsArray["Μεταβολή"] = "Μεταβολή";
        }
        setColumns([columnsArray]);
        setTitle(textPdf);
        setDataFileTable(arrayToExport);
      } else {
        for (const i in array) {
          for (const j in array[i]) {
            let dataToExport = {};
            dataToExport[dateLabel()] = dateFormat(array[i][j].x);
            dataToExport["Πλήθος"] = array[i][j].y;
            arrayToExport.push(dataToExport);
          }
        }
        let columnsArray = {};
        columnsArray[dateLabel()] = dateLabel();
        columnsArray["Πλήθος"] = "Πλήθος";
        setColumns([columnsArray]);
        setTitle("");
        setDataFileTable(arrayToExport);
      }
    }
  };

  const exportToPDF = () => {
    generatePDF(dataFileTable, columns, title);
  };

  const onCopy = () => {
    setCopied(true);
  };

  const handleClose = () => {
    setCopied(false);
  };
  const copyTable = () => {
    const elTable = document.querySelector('table');
    if (elTable) {
      let tableText = '';
      const rows = elTable.querySelectorAll('tr');
      rows.forEach(row => {
        const rowText = Array.from(row.querySelectorAll('th, td'))
          .map(cell => cell.innerText.trim())
          .join('\t');
        tableText += rowText + '\n';
      });

      navigator.clipboard.writeText(tableText)
        .then(() => {
          console.log('Table copied to clipboard as plain text!');
        })
        .catch(err => {
          console.error('Failed to copy table:', err);
        });
    }
  };

  const dateComparisonLabel = () => {
    switch (currentDateIndex) {
      case 0:
        return "Ημερομηνία Σύγκρισης";
      case 1:
        return "Εβδομάδα Σύγκρισης (που ξεκινάει από)";
      case 2:
        return "Μήνας Σύγκρισης";
      case 3:
        return "Έτος Σύγκρισης";
    }
  };

  const dateLabel = () => {
    switch (currentDateIndex) {
      case 0:
        return "Ημερομηνία";
      case 1:
        return "Εβδομάδα (που ξεκινάει από)";
      case 2:
        return "Μήνας";
      case 3:
        return "Έτος";
    }
  };

  const dateFormat = (value) => {
    switch (currentDateIndex) {
      case 0:
        return moment(value).format("DD/MM/YYYY");
      case 1:
        return moment(value).format("DD/MM/YYYY");
      case 2:
        return moment(value).format("MM/YYYY");
      case 3:
        return moment(value).format("YYYY");
      case 4:
        return moment(value).format("DD/MM/YYYY");
      case 5:
        return moment(value).format("DD/MM/YYYY");
    }
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={copied}
        autoHideDuration={4000}
        onClose={handleClose}
        message="Αντιγράφηκε επιτυχώς!"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <Grid item xs={12} className={classes.titleButton}>
        <div style={{ display: "contents" }}>
          <Typography
            component="h6"
            variant="h6"
            colSpan={6}
            className={classes.typographyTitle}
          >
            Αναλυτικά Αποτελέσματα
          </Typography>
        </div>
      </Grid>
      <Button
        startIcon={<FileCopyIcon />}
        id="filter-button"
        type="submit"
        variant="outlined"
        className={classes.filterButton}
        size="small"
        onClick={() => {
          onCopy();
          copyTable("table");
        }}
      >
        Αντιγραφή
      </Button>
      <Button
        startIcon={<GridOnIcon />}
        id="filter-button"
        type="submit"
        variant="outlined"
        className={classes.filterButton}
        onClick={() => ExportToExcel(dataFileTable, "Αποτελέσματα")}
        size="small"
      >
        EXCEL
      </Button>
      <Button
        startIcon={<GridOnIcon />}
        id="filter-button"
        type="submit"
        variant="outlined"
        className={classes.filterButton}
        onClick={() => ExportToCSV(dataFileTable, "Αποτελέσματα")}
        size="small"
      >
        CSV
      </Button>
      <Button
        startIcon={<PictureAsPdfIcon />}
        id="filter-button"
        type="submit"
        variant="outlined"
        className={classes.filterButton}
        size="small"
        onClick={exportToPDF}
      >
        PDF
      </Button>
      <ReactToPrint
        trigger={() => (
          <Button
            startIcon={<PrintIcon />}
            id="filter-button"
            type="submit"
            variant="outlined"
            className={classes.filterButton}
            size="small"
          >
            Εκτύπωση
          </Button>
        )}
        content={() => componentRef.current}
      />
      <Grid container className={classes.root} spacing={2}>
        {dataTable.length > 0 ? (
          !freeRangeCompare && compare ? (
            dataTable.map((data) => {
              return (
                <>
                  <Accordion
                    key={data[0].id}
                    square
                    className={classes.accordion}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="content"
                      id="header"
                    >
                      <Typography className={classes.heading}>
                        <div className={classes.title}>
                          {dateFormat(data[0].x)}
                        </div>
                      </Typography>
                      <Typography className={classes.fullTitle}>
                        {regDelCompareType ? null : `${format(",d")(data[0].y)}`}
                      </Typography>
                    </AccordionSummary>
                    <div className={classes.accordion}>
                      {regDelCompareType ? (
                        <TableRow>
                          <TableCell
                            id="slug"
                            align="right"
                            m={1}
                            className={classes.background}
                          >
                            Πλήθος Εγγραφών
                          </TableCell>
                          <TableCell
                            id="slug"
                            align="right"
                            m={1}
                            className={classes.background}
                          >
                            Πλήθος Διαγραφών
                          </TableCell>
                          <TableCell
                            id="slug"
                            align="right"
                            m={1}
                            className={classes.background}
                          >
                            Μεταβολή (Εγγραφές-Διαγραφές)
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow>
                          <TableCell
                            id="body"
                            align="left"
                            m={1}
                            className={classes.background}
                          >
                            {dateComparisonLabel()}
                          </TableCell>
                          <TableCell
                            id="body"
                            align="left"
                            m={1}
                            className={classes.background}
                          >
                            Πλήθος Συγκρισης
                          </TableCell>
                          <TableCell
                            id="body"
                            align="left"
                            m={1}
                            className={classes.background}
                          >
                            Μεταβολή
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        {regDelCompareType ? (
                          <>
                            <TableCell align="right">{format(",d")(data[0].y)}</TableCell>
                            <TableCell align="right">{format(",d")(data[1].y)}</TableCell>
                            <TableCell align="right">
                              {format(",d")(data[0].change)}
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell align="right">
                              {dateFormat(data[1].x)}
                            </TableCell>
                            <TableCell align="right">{format(",d")(data[1].y)}</TableCell>
                            <TableCell align="right">
                              {format(",d")(data[0].change)}
                            </TableCell>
                          </>
                        )}
                      </TableRow>
                    </div>
                  </Accordion>
                </>
              );
            })
          ) : (
            <TableContainer className={classes.paper} tabIndex={0}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead id="header">
                  <TableRow>
                    <TableCell
                      id="body"
                      align="left"
                      m={1}
                      className={classes.background}
                    >
                      {dateLabel()}
                    </TableCell>
                    <TableCell
                      id="slug"
                      align="left"
                      m={1}
                      className={classes.background}
                    >
                      Πλήθος
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody id="table-body">
                  {dataTable.map(function (option) {
                    return option.map(function (data) {
                      return (
                        <Fragment key={data.id}>
                          <TableRow key={data.id} id={data.id}>
                            <TableCell>{dateFormat(data.x)}</TableCell>
                            <TableCell>{format(",d")(data.y)}</TableCell>
                          </TableRow>
                        </Fragment>
                      );
                    });
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )
        ) : (
          <></>
        )}
      </Grid>
      <div style={{ display: "none" }} id="table">
        <TableContainer ref={componentRef} className={classes.paperForPrint} tabIndex={0}>
          <Table aria-label="sticky table">
            <TableHead id="header">
              <TableRow>
                <TableCell
                  id="body"
                  align="left"
                  m={1}
                  className={classes.background}
                >
                  {dateLabel()}
                </TableCell>
                {regDelCompareType ? null : (
                  <TableCell
                    id="slug"
                    align="right"
                    m={1}
                    className={classes.background}
                  >
                    Πλήθος
                  </TableCell>
                )}
                {!freeRangeCompare && compare ? (
                  regDelCompareType ? (
                    <>
                      <TableCell
                        id="slug"
                        align="right"
                        m={1}
                        className={classes.background}
                      >
                        Πλήθος Εγγραφών
                      </TableCell>
                      <TableCell
                        id="slug"
                        align="right"
                        m={1}
                        className={classes.background}
                      >
                        Πλήθος Διαγραφών
                      </TableCell>
                      <TableCell
                        id="slug"
                        align="right"
                        m={1}
                        className={classes.background}
                      >
                        Μεταβολή (Εγγραφές-Διαγραφές)
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell
                        id="slug"
                        align="right"
                        m={1}
                        className={classes.background}
                      >
                        {dateComparisonLabel()}
                      </TableCell>
                      <TableCell
                        id="slug"
                        align="right"
                        m={1}
                        className={classes.background}
                      >
                        Πλήθος Σύγκρισης
                      </TableCell>
                      <TableCell
                        id="slug"
                        align="right"
                        m={1}
                        className={classes.background}
                      >
                        Μεταβολή
                      </TableCell>
                    </>
                  )
                ) : null}
              </TableRow>
            </TableHead>
            <TableBody id="table-body">
              {dataTable.length > 0 ? (
                !freeRangeCompare && compare ? (
                  dataTable.map(function (data) {
                    return (
                      <Fragment key={data[0].id}>
                        <TableRow key={data[0].id} id={data[0].id}>
                          <TableCell>{dateFormat(data[0].x)}</TableCell>
                          <TableCell align="right">{format(",d")(data[0].y)}</TableCell>
                          {regDelCompareType ? null : (
                            <TableCell align="right">
                              {dateFormat(data[1].x)}
                            </TableCell>
                          )}
                          <TableCell align="right">{format(",d")(data[1].y)}</TableCell>
                          <TableCell align="right">{format(",d")(data[0].change)}</TableCell>
                        </TableRow>
                      </Fragment>
                    );
                  })
                ) : (
                  dataTable.map(function (option) {
                    return option.map(function (data) {
                      return (
                        <Fragment key={data.id}>
                          <TableRow key={data.id} id={data.id}>
                            <TableCell>{dateFormat(data.x)}</TableCell>
                            <TableCell align="right">{format(",d")(data.y)}</TableCell>
                          </TableRow>
                        </Fragment>
                      );
                    });
                  })
                )
              ) : (
                <></>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default EstablishmentsTableMobile;
