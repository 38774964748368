import { fetcher } from '../fetcher';

export const getEstablishments = async (method, url, params, body) => {
    try {
        return await fetcher(method, url, params, body);
    } catch (error) {
        console.error(error);
    }
};

export default getEstablishments;