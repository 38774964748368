import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { useCallback, useState, useRef, useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { BodyCompareHandling } from '../../../src/context/BodyCompareContext';
import { BodyHandling } from '../../../src/context/BodyContext';
import { CompareHandling } from '../../../src/context/CompareContext';
import { DataHandling } from '../../../src/context/DataContext';
import { DateIndexHandling } from '../../../src/context/DateIndexContext';
import { DateYearsHandling } from '../../../src/context/DateYearsContext';
import { FreeRangeCompareHandling } from '../../../src/context/FreeRangeCompareContext';
import { LoadingHandling } from '../../../src/context/LoadingContext';
import { TypeHandling } from '../../../src/context/TypeContext';
import useResizeObserver from '../../assets/useResizeObserver';
import GroupPlots from '../../charts/GroupPlots';
import PieInsights from '../../charts/insights/PieInsights';
import SinglePlot from '../../charts/SinglePlot';
import FiltersEstablishments from '../../filters/FiltersEstablishments';
import EstablishmentsTableMobile from '../../mobile/EstablishmentsTableMobile';
import FiltersEstablishmentsMobile from '../../mobile/FiltersEstablishmentsMobile';
import EstablishmentsTable from '../../tables/EstablishmentsTable';
import EstablishmentsTableForPrint from '../../tablesForPrint/EstablishmentsTable';
import Button from '@material-ui/core/Button';
import PrintIcon from '@material-ui/icons/Print';
import ReactToPrint from 'react-to-print';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  typography: {
    display: 'flex',
    flex: 1,
  },
  groupButtons: {
    justifyContent: 'flex-start',
    display: 'flex',
    verticalAlign: 'top',
    width: '100%',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    alignContent: 'stretch',
  },
  filterButton: {
    color: theme.palette.secondary.main,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 0,
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.secondary.main,
    },
  },
  titleButton: {
    justifyContent: 'flex-end',
    display: 'flex',
    verticalAlign: 'top',
    width: '100%',
    flexWrap: 'nowrap',
    alignItems: 'stretch',
    alignContent: 'stretch',
    padding: 0,
    marginRight: 8,
    [theme.breakpoints.down('sm')]: {
      display: 'contents',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  gridContainer1: {
    margin: theme.spacing(1),
    width: '100%',
  },
  gridContainer2: {
    margin: theme.spacing(1),
    width: '100%',
    zIndex: 5,
  },
  gridContainer3: {
    margin: theme.spacing(1),
    width: '100%',
  },
  gridContainer4: {
    margin: theme.spacing(1),
    width: '100%',
  },
  print: {
    display: 'none',
    margin: theme.spacing(1),
    width: '100%',
  },
}));

const EstablishmentsPage = (props) => {
  const classes = useStyles();
  const { establishments, states, legalForms, codes } = props;
  const [dateYearsSelected, setDateYearsSelected] = useState(true);
  const [bodyInsight, setBodyInsight] = useState();
  const [data, setData] = useState();
  const [regDelCompareType, setRegDelCompareType] = useState(false);
  const [currentDateIndex, setCurrentDateIndex] = useState(1);
  const [freeRangeCompare, setFreeRangeCompare] = useState(false);
  const [compare, setCompare] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [ref, { contentRect }] = useResizeObserver();
  const [compareBodyInsight, setCompareBodyInsight] = useState({
    startDate: '',
    endDate: '',
    states: [],
    legalForms: [],
    inactiveStatus: process.env.NEXT_PUBLIC_CHAMBER_I18N_ID.split(', '),
    codes: [],
    codeKAD: process.env.NEXT_PUBLIC_CODING_SYSTEM,
    professionKind: process.env.NEXT_PUBLIC_KIND,
  });
  const componentRef = useRef();

  const getContentRect = useCallback(
    (key) => contentRect && Math.round(contentRect[key]),
    [contentRect]
  );

  const showTable = () => {
    document.getElementById('tableForPrint').style.display = 'block';
  };

  const hideTable = () => {
    document.getElementById('tableForPrint').style.display = 'none';
  };

  return (
    <Container
      direction='column'
      id='container'
      maxWidth='xl'
      className={classes.container}
    >
      <BodyHandling.Provider value={{ bodyInsight, setBodyInsight }}>
        <BodyCompareHandling.Provider
          value={{ compareBodyInsight, setCompareBodyInsight }}
        >
          <CompareHandling.Provider value={{ compare, setCompare }}>
            <LoadingHandling.Provider value={{ loadingState, setLoadingState }}>
              <DateYearsHandling.Provider
                value={{ dateYearsSelected, setDateYearsSelected }}
              >
                <DataHandling.Provider value={{ data, setData }}>
                  <FreeRangeCompareHandling.Provider
                    value={{ freeRangeCompare, setFreeRangeCompare }}
                  >
                    <TypeHandling.Provider
                      value={{ regDelCompareType, setRegDelCompareType }}
                    >
                      <DateIndexHandling.Provider
                        value={{ currentDateIndex, setCurrentDateIndex }}
                      >
                        <Grid container id='grid-container' spacing={3}>
                          {isMobileOnly ? (
                            <>
                              <Grid
                                item
                                id='box1'
                                className={classes.gridContainer1}
                              >
                                <FiltersEstablishmentsMobile
                                  title='Εγγραφές/Διαγραφές'
                                  types={establishments}
                                  states={states}
                                  legalForms={legalForms}
                                  codes={codes}
                                />
                              </Grid>
                              <Grid
                                item
                                id='box3'
                                className={classes.gridContainer3}
                              >
                                <SinglePlot />
                              </Grid>
                              <Grid
                                item
                                id='box3'
                                className={classes.gridContainer3}
                              >
                                <EstablishmentsTableMobile />
                              </Grid>
                            </>
                          ) : (
                            <>
                              {process.env.printData ? (
                                <Grid className={classes.titleButton}>
                                  <div style={{ display: 'contents' }}>
                                    <ReactToPrint
                                      trigger={() => (
                                        <Button
                                          startIcon={<PrintIcon />}
                                          id='filter-button'
                                          type='submit'
                                          variant='outlined'
                                          className={classes.filterButton}
                                          size='small'
                                        >
                                          Εκτυπωση
                                        </Button>
                                      )}
                                      content={() => componentRef.current}
                                      onBeforeGetContent={showTable}
                                      onAfterPrint={hideTable}
                                    />
                                  </div>
                                </Grid>
                              ) : (
                                <></>
                              )}
                              <Grid
                                id='grid-container'
                                ref={componentRef}
                                style={{
                                  width: '100%',
                                  boxSizing: 'border-box',
                                }}
                              >
                                <Grid
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                  }}
                                  ref={ref}
                                >
                                  {getContentRect('width') > 650 ? (
                                    <>
                                      <Grid
                                        item
                                        id='box1'
                                        className={classes.gridContainer1}
                                      >
                                        <FiltersEstablishments
                                          title='Εγγραφές/Διαγραφές'
                                          types={establishments}
                                          states={states}
                                          legalForms={legalForms}
                                          codes={codes}
                                        />
                                      </Grid>
                                      <Grid
                                        item
                                        id='box2'
                                        className={classes.gridContainer2}
                                      >
                                        <PieInsights
                                          method='POST'
                                          url='establishments/byType'
                                          title='Εγγραφές / Διαγραφές '
                                        />
                                      </Grid>
                                    </>
                                  ) : (
                                    <Grid
                                      item
                                      id='box1'
                                      className={classes.gridContainer1}
                                    >
                                      <FiltersEstablishments
                                        title='Εγγραφές/Διαγραφές'
                                        types={establishments}
                                        states={states}
                                        legalForms={legalForms}
                                        codes={codes}
                                      />
                                    </Grid>
                                  )}
                                </Grid>
                                {getContentRect('width') < 650 ? (
                                  <Grid
                                    item
                                    id='box2'
                                    className={classes.gridContainer2}
                                  >
                                    <PieInsights
                                      method='POST'
                                      url='establishments/byType'
                                      title='Εγγραφές / Διαγραφές '
                                    />
                                  </Grid>
                                ) : (
                                  <></>
                                )}
                                <Grid
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                  }}
                                >
                                  <Grid
                                    item
                                    id='box3'
                                    className={classes.gridContainer3}
                                  >
                                    <GroupPlots />
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  id='tableForPrint'
                                  className={classes.print}
                                >
                                  <EstablishmentsTableForPrint />
                                </Grid>
                              </Grid>

                              <Grid
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  width: '100%',
                                }}
                              >
                                <Grid
                                  item
                                  id='box3'
                                  className={classes.gridContainer3}
                                >
                                  <EstablishmentsTable />
                                </Grid>
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </DateIndexHandling.Provider>
                    </TypeHandling.Provider>
                  </FreeRangeCompareHandling.Provider>
                </DataHandling.Provider>
              </DateYearsHandling.Provider>
            </LoadingHandling.Provider>
          </CompareHandling.Provider>
        </BodyCompareHandling.Provider>
      </BodyHandling.Provider>
    </Container>
  );
};

export default EstablishmentsPage;
