import React from "react";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List
} from "react-virtualized";

export const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, role, ...other } = props;
  const itemData = React.Children.toArray(children);

  const cache = new CellMeasurerCache({
    defaultHeight: 50,
    fixedWidth: true,
  });

  function renderRowData({ index, key, style, parent }) {
    return (
      <CellMeasurer
        key={key}
        cache={cache}
        parent={parent}
        columnIndex={0}
        rowIndex={index}
      >
        <div style={style}>
          <div style={{ width: "100%" }}>
            <div>
              {React.cloneElement(itemData[index], {
                style: props.style,
              })}
            </div>
          </div>
        </div>
      </CellMeasurer>
    );
  }

  return (
    <div ref={ref}>
      <div {...other} style={{ height: 300 }}>
        <AutoSizer>
          {({ height, width }) => (
            <List
              width={width}
              height={height}
              deferredMeasurementCache={cache}
              rowHeight={cache.rowHeight}
              rowRenderer={renderRowData}
              rowCount={itemData.length}
              overscanRowCount={5}
              role={role}
            />
          )}
        </AutoSizer>
      </div>
    </div>
  );
});